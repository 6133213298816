import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as i0 from '@angular/core';
import { Directive, forwardRef, Component, ViewEncapsulation, Optional, Self, ViewChild, ContentChild, Input, NgModule } from '@angular/core';
import * as i3 from '@angular/material/core';
import { mixinErrorState } from '@angular/material/core';
import * as i7 from '@angular/material/form-field';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import * as i1 from '@angular/cdk/platform';
import * as i2 from '@angular/forms';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i5 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i6 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
const _c0 = ["inputFile"];
const _c1 = ["inputValue"];
const _c2 = [[["", "ngxMatFileInputIcon", ""]]];
const _c3 = ["[ngxMatFileInputIcon]"];
function NgxMatFileInputComponent_mat_icon_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 7);
    i0.ɵɵtext(1, "attach_file");
    i0.ɵɵelementEnd();
  }
}
let nextUniqueId = 0;
const _NgxMatInputMixinBase = mixinErrorState(class {
  constructor(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, /** @docs-private */
  ngControl) {
    this._defaultErrorStateMatcher = _defaultErrorStateMatcher;
    this._parentForm = _parentForm;
    this._parentFormGroup = _parentFormGroup;
    this.ngControl = ngControl;
    this.stateChanges = new Subject();
  }
});
class NgxMatFileInputIcon {}
/** @nocollapse */
NgxMatFileInputIcon.ɵfac = function NgxMatFileInputIcon_Factory(t) {
  return new (t || NgxMatFileInputIcon)();
};
/** @nocollapse */
NgxMatFileInputIcon.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NgxMatFileInputIcon,
  selectors: [["", "ngxMatFileInputIcon", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatFileInputIcon, [{
    type: Directive,
    args: [{
      selector: '[ngxMatFileInputIcon]'
    }]
  }], null, null);
})();
class NgxMatFileInputComponent extends _NgxMatInputMixinBase {
  constructor(_elementRef, _platform, _cd, ngControl, _parentForm, _parentFormGroup, _defaultErrorStateMatcher) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    this._elementRef = _elementRef;
    this._platform = _platform;
    this._cd = _cd;
    this.ngControl = ngControl;
    this.color = 'primary';
    this.fileNames = null;
    this._uid = `ngx-mat-fileinput-${nextUniqueId++}`;
    this.stateChanges = new Subject();
    this.focused = false;
    this.controlType = 'ngx-mat-file-input';
    this.autofilled = false;
    /** Function when touched */
    this._onTouched = () => {};
    /** Function when changed */
    this._onChange = () => {};
    this._disabled = false;
    this._multiple = false;
    this.placeholder = 'Choose a file';
    this.separator = ',';
    this._required = false;
    this._readonly = true;
    this.id = this.id;
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  get disabled() {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  get id() {
    return this._id;
  }
  set id(value) {
    this._id = value || this._uid;
  }
  get multiple() {
    return this._multiple;
  }
  set multiple(value) {
    this._multiple = coerceBooleanProperty(value);
  }
  get required() {
    return this._required;
  }
  set required(value) {
    this._required = coerceBooleanProperty(value);
  }
  get value() {
    return this._value;
  }
  set value(value) {
    this._value = value;
  }
  get readonly() {
    return this._readonly;
  }
  set readonly(value) {
    this._readonly = coerceBooleanProperty(value);
  }
  /**
   * Limiting accepted file types
   * Example: accept="image/png, image/jpeg" or accept=".png, .jpg, .jpeg" — Accept PNG or JPEG files.
   */
  get accept() {
    return this._accept;
  }
  set accept(value) {
    this._accept = value;
  }
  ngOnChanges() {
    this.stateChanges.next();
  }
  ngOnDestroy() {
    this.stateChanges.complete();
  }
  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }
  // Implemented as part of ControlValueAccessor.
  writeValue(value) {
    this._updateInputValue(value);
  }
  // Implemented as part of ControlValueAccessor.
  registerOnChange(fn) {
    this._onChange = fn;
  }
  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }
  /** Focuses the input. */
  focus(options) {
    this._inputValueRef.nativeElement.focus(options);
  }
  _focusChanged(isFocused) {
    if (isFocused !== this.focused && (!this.readonly || !isFocused)) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }
  /** Mark the field as touched */
  _markAsTouched() {
    this._onTouched();
    this._cd.markForCheck();
    this.stateChanges.next();
  }
  _isBadInput() {
    let validity = this._inputValueRef.nativeElement.validity;
    return validity && validity.badInput;
  }
  get empty() {
    return !this._inputValueRef.nativeElement.value && !this._isBadInput() && !this.autofilled;
  }
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  setDescribedByIds(ids) {
    this._ariaDescribedby = ids.join(' ');
  }
  openFilePicker(event) {
    this._inputFileRef.nativeElement.click();
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this._markAsTouched();
  }
  handleFiles(filelist) {
    if (filelist.length > 0) {
      const files = new Array();
      for (let i = 0; i < filelist.length; i++) {
        files.push(filelist.item(i));
      }
      this._updateInputValue(files);
      this._resetInputFile();
      this._onChange(this.multiple ? files : files[0]);
    }
  }
  /** Handles a click on the control's container. */
  onContainerClick(event) {}
  _resetInputFile() {
    this._inputFileRef.nativeElement.value = "";
  }
  _updateInputValue(files) {
    let text = null;
    if (files) {
      if (Array.isArray(files)) {
        text = this._multiple ? files.map(x => x.name).join(this.separator) : files[0].name;
      } else {
        text = files.name != null ? files.name : null;
      }
    }
    this._inputValueRef.nativeElement.value = text;
  }
}
/** @nocollapse */
NgxMatFileInputComponent.ɵfac = function NgxMatFileInputComponent_Factory(t) {
  return new (t || NgxMatFileInputComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.Platform), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i2.NgControl, 10), i0.ɵɵdirectiveInject(i2.NgForm, 8), i0.ɵɵdirectiveInject(i2.FormGroupDirective, 8), i0.ɵɵdirectiveInject(i3.ErrorStateMatcher));
};
/** @nocollapse */
NgxMatFileInputComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgxMatFileInputComponent,
  selectors: [["ngx-mat-file-input"]],
  contentQueries: function NgxMatFileInputComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, NgxMatFileInputIcon, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._customIcon = _t.first);
    }
  },
  viewQuery: function NgxMatFileInputComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
      i0.ɵɵviewQuery(_c1, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._inputFileRef = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._inputValueRef = _t.first);
    }
  },
  hostAttrs: [1, "ngx-mat-file-input"],
  inputs: {
    color: "color",
    disabled: "disabled",
    id: "id",
    multiple: "multiple",
    placeholder: "placeholder",
    separator: "separator",
    required: "required",
    errorStateMatcher: "errorStateMatcher",
    value: "value",
    readonly: "readonly",
    accept: "accept"
  },
  exportAs: ["ngx-mat-file-input"],
  features: [i0.ɵɵProvidersFeature([{
    provide: MatFormFieldControl,
    useExisting: forwardRef(() => NgxMatFileInputComponent)
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
  ngContentSelectors: _c3,
  decls: 8,
  vars: 13,
  consts: [["inputValue", ""], ["inputFile", ""], ["autocomplete", "off", 1, "mat-mdc-input-element", "mat-mdc-form-field-input-control", "mdc-text-field__input", 3, "disabled", "required"], [1, "mat-mdc-form-field-suffix"], ["matSuffix", "", "mat-icon-button", "", "type", "button", 1, "button-browse", 3, "click", "color", "disabled"], ["class", "ngx-mat-file-input--default-icon", 4, "ngIf"], ["type", "file", 1, "input-file", 3, "change", "multiple", "accept"], [1, "ngx-mat-file-input--default-icon"]],
  template: function NgxMatFileInputComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef(_c2);
      i0.ɵɵelement(0, "input", 2, 0);
      i0.ɵɵelementStart(2, "div", 3)(3, "button", 4);
      i0.ɵɵlistener("click", function NgxMatFileInputComponent_Template_button_click_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.openFilePicker($event));
      });
      i0.ɵɵtemplate(4, NgxMatFileInputComponent_mat_icon_4_Template, 2, 0, "mat-icon", 5);
      i0.ɵɵprojection(5);
      i0.ɵɵelementEnd()();
      i0.ɵɵelementStart(6, "input", 6, 1);
      i0.ɵɵlistener("change", function NgxMatFileInputComponent_Template_input_change_6_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.handleFiles($event.target.files));
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("disabled", ctx.disabled)("required", ctx.required);
      i0.ɵɵattribute("id", ctx.id)("placeholder", ctx.placeholder)("readonly", ctx.readonly || null)("aria-describedby", ctx._ariaDescribedby || null)("aria-invalid", ctx.errorState)("aria-required", ctx.required.toString());
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("color", ctx.color)("disabled", ctx.disabled);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx._customIcon);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("multiple", ctx.multiple)("accept", ctx.accept);
    }
  },
  dependencies: [i4.NgIf, i5.MatIconButton, i6.MatIcon, i7.MatSuffix],
  styles: [".mat-mdc-form-field-appearance-outline .mat-form-field-prefix .ngx-mat-file-input--default-icon,.mat-mdc-form-field-appearance-outline .mat-form-field-suffix .ngx-mat-file-input--default-icon{width:1em}.mat-mdc-form-field:not(.mat-form-field-appearance-outline) .mat-form-field-prefix .ngx-mat-file-input--default-icon,.mat-mdc-form-field:not(.mat-form-field-appearance-outline) .mat-form-field-suffix .ngx-mat-file-input--default-icon{display:block;width:1.5em;height:1.5em}.mat-mdc-form-field:not(.mat-form-field-appearance-outline) .mat-form-field-prefix .mat-icon-button .ngx-mat-file-input--default-icon,.mat-mdc-form-field:not(.mat-form-field-appearance-outline) .mat-form-field-suffix .mat-icon-button .ngx-mat-file-input--default-icon{margin:auto}.ngx-mat-file-input{display:flex;line-height:18px;align-items:center}.ngx-mat-file-input .input-file{display:block;visibility:hidden;width:0;height:0}\n"],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatFileInputComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-mat-file-input',
      encapsulation: ViewEncapsulation.None,
      host: {
        'class': 'ngx-mat-file-input'
      },
      providers: [{
        provide: MatFormFieldControl,
        useExisting: forwardRef(() => NgxMatFileInputComponent)
      }],
      exportAs: 'ngx-mat-file-input',
      template: "<input #inputValue autocomplete=\"off\"\r\n  class=\"mat-mdc-input-element  mat-mdc-form-field-input-control mdc-text-field__input\" [attr.id]='id'\r\n  [attr.placeholder]='placeholder' [disabled]='disabled' [required]='required' [attr.readonly]='readonly || null'\r\n  [attr.aria-describedby]='_ariaDescribedby || null' [attr.aria-invalid]='errorState'\r\n  [attr.aria-required]='required.toString()'>\r\n<div class=\"mat-mdc-form-field-suffix\">\r\n  <button matSuffix mat-icon-button [color]=\"color\" class=\"button-browse\" (click)=\"openFilePicker($event)\" type=\"button\"\r\n    [disabled]=\"disabled\">\r\n    <mat-icon *ngIf=\"!_customIcon\" class=\"ngx-mat-file-input--default-icon\">attach_file</mat-icon>\r\n    <ng-content select=\"[ngxMatFileInputIcon]\"></ng-content>\r\n  </button>\r\n</div>\r\n<input type=\"file\" #inputFile (change)=\"handleFiles($event.target.files)\" class=\"input-file\" [multiple]=\"multiple\"\r\n  [accept]=\"accept\">",
      styles: [".mat-mdc-form-field-appearance-outline .mat-form-field-prefix .ngx-mat-file-input--default-icon,.mat-mdc-form-field-appearance-outline .mat-form-field-suffix .ngx-mat-file-input--default-icon{width:1em}.mat-mdc-form-field:not(.mat-form-field-appearance-outline) .mat-form-field-prefix .ngx-mat-file-input--default-icon,.mat-mdc-form-field:not(.mat-form-field-appearance-outline) .mat-form-field-suffix .ngx-mat-file-input--default-icon{display:block;width:1.5em;height:1.5em}.mat-mdc-form-field:not(.mat-form-field-appearance-outline) .mat-form-field-prefix .mat-icon-button .ngx-mat-file-input--default-icon,.mat-mdc-form-field:not(.mat-form-field-appearance-outline) .mat-form-field-suffix .mat-icon-button .ngx-mat-file-input--default-icon{margin:auto}.ngx-mat-file-input{display:flex;line-height:18px;align-items:center}.ngx-mat-file-input .input-file{display:block;visibility:hidden;width:0;height:0}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1.Platform
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i2.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }]
    }, {
      type: i2.NgForm,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i2.FormGroupDirective,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i3.ErrorStateMatcher
    }];
  }, {
    _inputFileRef: [{
      type: ViewChild,
      args: ['inputFile', {
        static: true
      }]
    }],
    _inputValueRef: [{
      type: ViewChild,
      args: ['inputValue', {
        static: true
      }]
    }],
    _customIcon: [{
      type: ContentChild,
      args: [NgxMatFileInputIcon]
    }],
    color: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    separator: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    errorStateMatcher: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    accept: [{
      type: Input
    }]
  });
})();
class NgxMatFileInputModule {}
/** @nocollapse */
NgxMatFileInputModule.ɵfac = function NgxMatFileInputModule_Factory(t) {
  return new (t || NgxMatFileInputModule)();
};
/** @nocollapse */
NgxMatFileInputModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxMatFileInputModule
});
/** @nocollapse */
NgxMatFileInputModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatFileInputModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxMatFileInputComponent, NgxMatFileInputIcon],
      imports: [CommonModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule],
      exports: [NgxMatFileInputComponent, NgxMatFileInputIcon]
    }]
  }], null, null);
})();
function calculFileSize(number) {
  if (number < 1024) {
    return number + 'bytes';
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + 'KB';
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + 'MB';
  }
}

/**
 * Validator for size of file
 * @param max Max of size of file (in bytes)
 */
function MaxSizeValidator(max) {
  return ctrl => {
    max = Number(max);
    if (isNaN(max)) {
      throw 'MaxSizeValidator: max of size of file is invalid';
    }
    if (!ctrl.value) return null;
    let files = ctrl.value;
    if (!Array.isArray(ctrl.value)) {
      files = [ctrl.value];
    }
    if (!files.length) return null;
    const add = (a, b) => a + b;
    const sumSize = files.map(x => x.size).reduce(add);
    if (sumSize > max) {
      return {
        maxSize: true
      };
    }
    return null;
  };
}

/**
 * Validator for input file accept
 * @param accept Allowable type of file
 */
function AcceptValidator(accept) {
  return ctrl => {
    if (!accept) {
      throw 'AcceptValidator: allowable type of file can not be empty';
    }
    if (ctrl.value == null) return null;
    if (!accept.includes(ctrl.value.type)) {
      return {
        accept: true
      };
    }
    return null;
  };
}

/*
 * Public API Surface of file-input
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AcceptValidator, MaxSizeValidator, NgxMatFileInputComponent, NgxMatFileInputIcon, NgxMatFileInputModule, calculFileSize };

import { Component, OnInit } from '@angular/core';
import { SignUp } from './sign-up.model';
import { UserdataService } from '../../servives/userdata.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.css'
})
export class SignUpComponent implements OnInit {

  signUp = new SignUp();
  resendVerificationLinkFlag: boolean=false;

  constructor(
    private userdata : UserdataService,
  ) {}

  ngOnInit(){
    this.resendVerificationLinkFlag = false;
    
  }

  //make update flag true for getting update form
  updateFlagTrue(status:boolean) {
    this.resendVerificationLinkFlag = status;
  }

  //resends user Verification Link
  resendVerificationLink()
  {
    this.userdata.resendVerificationLink(this.signUp).subscribe( (response: any) =>{
     
      if(response.status == 200)
      {
        alert(response.message);
      }
      else if(response.status == 400)
      {
        alert(response.message);
      }
    });
  }

  //adds new user for dashboard
  registerUser()
  {

    this.userdata.registerUser(this.signUp).subscribe( (response: any) =>{ 

      if(response.status == 200)
      {
        alert(response.message);
      }
      else if(response.status == 400)
      {
        alert(response.message);
      }
    });
  }

}

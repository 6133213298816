import { Component, OnInit, ViewChild } from '@angular/core';
import { Tables } from '../../common/common-tables';
import { Course } from './course.model';
import { UserdataService } from '../../servives/userdata.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { KEY_COURSEDETAILS, ROUTE_ID, ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_COURSES } from '../../utils/app-constants';
import { ImageUploadsComponent } from '../../directive/image-uploads/image-uploads.component';
import { LocalStorageService } from '../../servives/local-storage.service';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrl: './course.component.css'
})
export class CourseComponent implements OnInit {
  commonTables: Tables[] = [];
  courseModel: any = new Course();
  id = null;
  selectedFile: File | null = null;
  imagePreview:any;
  devId: any;
  is_active: any;

  API_URL = environment.API_URL;
  imageUrl: any;

  courseName: any;
  shortInfo: any;
  isActive: any;
  courseDuration: any;
  courseTime: any;
  courseMode: any;
  contact: any;

  //to access properties or methods from other components, assume this is parent 
  //component and make child referance of other component
  @ViewChild(ImageUploadsComponent)
  ImageUploadsComponentRef!: ImageUploadsComponent;

  

  constructor(
    private userData: UserdataService,
    private route: ActivatedRoute,
    private router: Router,
    protected commonMethodsService: CommonMethodsService,
    private localStorage: LocalStorageService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params[ROUTE_ID];

    if (this.id != null && this.id != undefined) {
      this.getOneData();
    }
  }

  //to set record's data in model to show in edit form when you 
    //click on edit(update) button
  getOneData() {
    this.userData.getOneCourse(this.id).subscribe((res: any) => {

      this.imageUrl = res.course_logo;
      this.courseModel = res;
      this.courseName = res.course_name;
      this.shortInfo = res.short_info;
      this.isActive = res.is_active;
      this.courseDuration = res.course_duration;
      this.courseTime = res.course_time;
      this.courseMode = res.course_mode;
      this.contact = res.contact;
    });
  }

    //check form is changed
  formChanged(): boolean {
    if (
      this.courseName !== this.courseModel.course_name ||
      this.shortInfo !== this.courseModel.short_info ||
      this.isActive !== this.courseModel.is_active ||
      this.courseDuration !== this.courseModel.course_duration ||
      this.courseTime !== this.courseModel.course_time ||
      this.courseMode !== this.courseModel.course_mode ||
      this.contact !== this.courseModel.contact ||
      this.selectedFile
    ) {
      return true;
    } else {
      return false;
    }
  }

  //common function for returning form data
  formData(isupdate: boolean) {
    //javascript method "FormData()" used to send form data
    const formData = new FormData();
    //in update method id is required but not in add method
    if(isupdate == true) {
      this.courseModel.id = this.id;
      formData.append('id', this.courseModel.id);
    }
    
    this.is_active = this.courseModel.is_active;
    formData.append('course_name', this.courseModel.course_name);
    formData.append('short_info', this.courseModel.short_info);
    formData.append('is_active', this.is_active);
    formData.append('course_duration', this.courseModel.course_duration);
    formData.append('course_time', this.courseModel.course_time);
    formData.append('course_mode', this.courseModel.course_mode);
    formData.append('contact', this.courseModel.contact);
    
    if (this.selectedFile) {
      formData.append('course_logo', this.selectedFile, this.selectedFile.name);
    }
    return formData;
  }

  //to update record
  updateData() {
    //this.localStorage.removeItem(KEY_COURSEDETAILS);
    this.selectedFile = this.ImageUploadsComponentRef.selectedFile;
    if(this.formChanged()) {
      //api call
      this.userData
        .updateCourse(this.formData(true))
        .subscribe((response: any) => {
          this.commonMethodsService.errorHandling(response);
          this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_COURSES]); 
        });
    }
  }

  //to add new record
  addData() {
    this.localStorage.removeItem(KEY_COURSEDETAILS);
    this.selectedFile = this.ImageUploadsComponentRef.selectedFile;
    if(this.selectedFile !== null) {
      this.userData.addCourse(this.formData(false)).subscribe((response: any) => {
        this.commonMethodsService.errorHandling(response);
        this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_COURSES]);
      });
    } else {
      alert("Picture is required!");
    }
  }

  //if checkbox(switch) is checked is_active status is true otherwise false
  onCheckboxChange(event: any) {
    const checkbox = event.currentTarget as HTMLInputElement;
    this.courseModel.is_active = checkbox.checked ? true : false;
  }

}

<div class="content">
    <svg *ngIf="(errorCode != 0) && (serverErrorCode != 0)" viewBox="0 0 960 300">
        <symbol id="s-text">

            <text *ngIf="errorCode" text-anchor="middle" x="50%" y="50%">{{errorCode}}</text>

            <text *ngIf="serverErrorCode" text-anchor="middle" x="50%" y="50%">{{serverErrorCode}}</text>


        </symbol>
    
        <g class = "g-ants">
            <use xlink:href="#s-text" class="text"></use>
            <use xlink:href="#s-text" class="text"></use>
            <use xlink:href="#s-text" class="text"></use>
            <use xlink:href="#s-text" class="text"></use>
            <use xlink:href="#s-text" class="text"></use>
        </g>
    </svg> 

    <h1>{{errorMessage}}</h1>
    @if(extractedValue == true) {
        <a  href="/#/dashboard">Back to Dashboard</a>
    }
    <!-- <a *ngIf="urlStartsWithDashboard && previousUrl != ''; else backToHome" href="/#/dashboard">Back to Dashboard</a> -->
    <!-- <ng-template #backToHome>
        <a href="/#/home">Back to Home</a>
    </ng-template> -->

</div>
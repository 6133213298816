<div class="bootstrap-wraper">
  <div class="container">
    <div class="row ml20 mb20">
      <div class="col-md-6 offset-md-3">
        <mat-card class="mt20 mb20" appearance="outlined" color="bule">
          <mat-card-content>
            <div class="container text-center">
              <img src="assets\logo.jpeg" alt="logo" class="imglogo" />
            </div>

            <h3 class="text-center">
              {{ confirmationPopup == true ? "Set " : "Reset " }} Your Password
            </h3>

            <form #form="ngForm" (ngSubmit)="confirmationPopup == true ? setPassword() : resetPassword()">
              <!-- email -->
              <mat-form-field class="full-width mb10" appearance="outline">
                <mat-label>Email</mat-label>
                <input
                  name="email"
                  matInput
                  placeholder="Enter hear"
                  type="email"
                  #userEmail="ngModel"
                  class="form-control pass"
                  name="email"
                  required
                  email
                  [(ngModel)]="setPasswordModel.email"
                />
                <mat-hint
                  >Enter email id on which you got the verification
                  link.</mat-hint
                >
                <span
                  *ngIf="
                    userEmail.invalid && userEmail.touched && !form.submitted
                  "
                  class="validationAlertColor"
                >
                  <span *ngIf="userEmail.errors?.['required']"
                    >Email is required.</span
                  >
                  <span *ngIf="userEmail.errors?.['email']"
                    >Please enter a valid email.</span
                  >
                </span>
              </mat-form-field>

              <!-- password -->
              <mat-form-field class="full-width mb10" appearance="outline">
                <mat-label>Password</mat-label>
                <input
                  [type]="show_password ? 'text' : 'password'"
                  matInput
                  placeholder="Enter hear"
                  class="form-control pass"
                  name="password"
                  [(ngModel)]="setPasswordModel.password"
                  required
                  #userPassword="ngModel"
                />
                <mat-hint>Minimum length 5</mat-hint>
                <i
                  class="fa pl-1"
                  [ngClass]="{
                    'fa-eye': !show_password,
                    'fa-eye-slash': show_password
                  }"
                  id="eye-icon"
                  (click)="showpasswordchars()"
                ></i>
                <span
                  *ngIf="
                    userPassword.invalid &&
                    userPassword.touched &&
                    !form.submitted
                  "
                  class="validationAlertColor"
                >
                  <span *ngIf="userPassword.errors?.['required']"
                    >Password is required.</span
                  >
                  <span *ngIf="userPassword.errors?.['minlength']"
                    >Password must be at least 5 and at most 20 characters
                    long.</span
                  >
                </span>
              </mat-form-field>
              <!--Confirm password -->
              <mat-form-field class="full-width mb10" appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <input
                  [type]="show_password ? 'text' : 'password'"
                  matInput
                  placeholder="Enter hear"
                  class="form-control pass"
                  name="confirmPassword"
                  [(ngModel)]="setPasswordModel.confirmPassword"
                  required
                  #userConfirmPassword="ngModel"
                />
                <mat-hint>Minimum length 5</mat-hint>
                <i
                  class="fa pl-1"
                  [ngClass]="{
                    'fa-eye': !show_password,
                    'fa-eye-slash': show_password
                  }"
                  id="eye-icon"
                  (click)="showpasswordchars()"
                ></i>
                <span
                  *ngIf="
                    userConfirmPassword.invalid &&
                    userConfirmPassword.touched &&
                    !form.submitted
                  "
                  class="validationAlertColor"
                >
                  <span *ngIf="userConfirmPassword.errors?.['required']"
                    >Password is required.</span
                  >
                  <span *ngIf="userConfirmPassword.errors?.['minlength']"
                    >Password must be at least 5 and at most 20 characters
                    long.</span
                  >
                </span>
                <span
                  *ngIf="
                    setPasswordModel.password !==
                    setPasswordModel.confirmPassword
                  "
                  class="validationAlertColor"
                >
                  Password not match
                </span>
              </mat-form-field>
              @if(confirmationPopup == true) {
                <!-- Profile picture -->
                <!-- ViewChild -->
                <app-image-uploads></app-image-uploads>
              }

              <div class="full-width mb20" appearance="outline">
                <span *ngIf="form.invalid" class="validationAlertColor"
                  >Please enter required fields.</span
                >
              </div>

              <div class="container text-center">
                <button
                  mat-raised-button
                  color="primary"
                  type="submit"
                  [disabled]="form.invalid"
                >
                  {{ confirmationPopup == true ? "Set " : "Reset " }} Password
                </button>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>

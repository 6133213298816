<main class="main">

    <!-- Page Title -->
    <div class="page-title" data-aos="fade">
        <div class="container">


            <!-- Section Title -->
            <div class="container section-title" data-aos="fade-up">
                <h2>Development Details</h2>
            </div><!-- End Section Title -->
            <!-- <h1>Portfolio Details</h1> -->


        </div>
    </div><!-- End Page Title -->

    <!-- Portfolio Details Section -->
    <section id="portfolio-details" class="portfolio-details section">

        <div class="container" data-aos="fade-up" data-aos-delay="100">

            <div class="row gy-4">

                <div class="col-lg-4">
                    <div class="portfolio-details-slider swiper init-swiper">



                        <div class="swiper-wrapper align-items-center">

                            <div class="swiper-slide">
                                <img class="blink" src="{{API_URL}}/{{devDetail.devLogo}}" data-aos="zoom-out"
                                    data-aos-delay="200" alt="">
                            </div>


                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="portfolio-info" data-aos="fade-up" data-aos-delay="200">
                        <h3>Development</h3>
                        <ul>
                            <li><strong>Category:</strong>: {{devDetail.devName}}</li>
                            <li><strong>Information:</strong>: {{devDetail.shortInfo}}</li>
                        </ul>
                    </div>

                </div>

            </div>
            <br><br>
            <!-- <div class="row gy-4">

                <div class="col-lg-4">
                </div>
                <div class="col-lg-8">
                    <div class="portfolio-info" data-aos="fade-up" data-aos-delay="200">
                        <h3>Course Activity</h3>
                        <ul>
                            <li><strong>Duration:</strong>:</li>
                            <li><strong>Time:</strong>: </li>
                            <li><strong>Mode:</strong>: </li>
                            <li><strong>Contact No.:</strong>: </li>

                        </ul>
                    </div>

                </div>
            </div> -->

        </div>

    </section><!-- /Portfolio Details Section -->

</main>
<mat-card class="mt10 mb10">
  <mat-action-list>
    <div mat-subheader>Menu</div>
    <div [innerHtml]="errorOrSuccessMsg"></div>
    <button routerLink="/dashboard" class="sidebar-btn" mat-list-item><mat-icon class="sidebar-icon">home</mat-icon> Dashboard Home</button>
    <button routerLink="/dashboard/profile" class="sidebar-btn" mat-list-item><mat-icon class="sidebar-icon">account_circle</mat-icon> Profile</button>
    <button #bt routerLink="/dashboard/signUp" class="sidebar-btn" mat-list-item><mat-icon class="sidebar-icon">account_circle</mat-icon> Add New User</button>
    <button href="#" *ngIf="tokenVal" (click)="logoutUser()" class="sidebar-btn" mat-list-item><mat-icon class="sidebar-icon">logout</mat-icon> Logout</button>

    <select (change)="navigate($event)"   mat-list-item class="table" name="table" id="table">
      <option value="">Select Table To Edit</option>
      <option value="menu">Menu</option>
      <option value="portfolio">Portfolio</option>
      <option value="services">Services</option>
      <option value="partners">Partners</option>
      <option value="courses">Courses</option>
      <option value="developments">Developments</option>
      <option value="city">City</option>
      <option value="companyInfo">Company information</option>
    </select>
    
  </mat-action-list>
</mat-card>











<!-- <select *ngFor="let tbl of tables" (click)="detailPage(tbl)" mat-list-item (change)="navigate($event)" class="table" name="table" id="table">
  <option value="/dashboard">Select Table To Edit</option>
  <option value="/dashboard/tabs">Menu</option>
  <option value="/dashboard/Courses">Courses</option>
  <option value="/dashboard/Services">Services</option>
  <option value="/dashboard/Partners">Partners</option>
  <option value="/dashboard/Portfolio">Portfolio</option>
  <option value="/dashboard/Developments">Developments</option>
  <option value="/dashboard/City">City</option>
  <option value="/dashboard/Company">Company information</option>
  <option value="/dashboard/Users">Users</option>
</select> -->
<mat-card>
  <mat-card-content>
    <div class="container">
      <div [innerHtml]="target"></div>
      <div class="mb-2 mt-5" style="text-align: center">
        <div>
           <span class="table-title" >Table-{{type}}</span>
        </div>
      </div>
      <div *ngIf="isShowAddButton" class="mb-2" style="text-align: end">
        <div>
           <button class=" btn btn-primary" routerLink="/dashboard/{{type}}" >Add New</button>
        </div>
      </div>
     

      <div class="row mb-2"></div>

      <div class="row">
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th class="w100">Sr. No.</th>
                <th>Name</th>
                <th class="w100">Is Active</th>
                <th>Description</th>
                <th>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let tb of tables">
                <td>{{ tb.id }}</td>
                <td>{{ tb.title | uppercase }}</td>
                <td>{{ tb.isActive }}</td>
                <td>{{ tb.desc }}</td>
                <td class="w20">
                  <button
                    class="btn btn-primary mx-2"
                    routerLink="/dashboard/{{type}}/{{ tb.id }}"
                  >
                    Edit
                  </button>                                  
                </td>
                <td class="w20">
                  <button class="btn btn-danger" (click)="deleteData(tb.id)">
                    Delete
                  </button>
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

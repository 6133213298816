import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallService } from '../../servives/api-call.service';
import { PortfolioList } from '../../common/portfolio-list';
import { HeaderService } from '../../servives/header.service';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_PRODUCTLIST, WEB_KEY } from '../../utils/app-constants';
import { WebData } from '../../utils/web-data';
import { environment } from '../../../environments/environment.development';
import { CommonMethodsService } from '../../servives/common-methods.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrl: './portfolio.component.css'
})
export class PortfolioComponent implements OnInit {

  portfolioList: PortfolioList[] = [];
  currentTab = ""
  companyName = ""
  API_URL = environment.API_URL;

  constructor(private routes: Router,
    private apiCallService: ApiCallService,
    private headerService: HeaderService,
    private localStorage: LocalStorageService,
    private router: Router,
    protected commonMethodsService: CommonMethodsService
  ) {


  }

  ngOnInit() {

    //checks if companyInfo date modified 
    this.isCompanyDateModified();

    this.currentTab = this.router.url
    this.companyName = this.localStorage.getItem(WEB_KEY)
    if (this.currentTab.toLowerCase().includes("portfolio")) {
      this.headerService.updateHeaderValue("Portfolio")
    }

    var productListObj = JSON.parse(this.localStorage.getItem(KEY_PRODUCTLIST))
    if (productListObj == null) {
      //portfolio list
      this.getPortFolioList()

      // this.portfolioList = WebData.portfolio
      // this.localStorage.setObject(KEY_PRODUCTLIST, this.portfolioList)
    } else {
      this.portfolioList = productListObj
    }
  }

  //checks if companyInfo date modified if yes then calls List api
  isCompanyDateModified() {
    this.commonMethodsService.isCompanyDateModified$.subscribe((data) => {
        if(data == true) {
          this.getPortFolioList();
          console.log("isCompanyDateChanged: "+data);
        }
    });
  }

  //portfolio list
  getPortFolioList() {
    this.apiCallService.getPortFolioListAPI().subscribe({
      next: (data: PortfolioList[]) => {
        this.portfolioList = data
        this.localStorage.setObject(KEY_PRODUCTLIST, this.portfolioList)

      },
      error: (err: any) => {

      },
      complete() {

      },

    })
  }

  //navigating to portfolio detail 
  detailPage(product: PortfolioList) {
    this.routes.navigate(["/portfoliodetail", product.id])
    // this.router.navigate(['/details', id]); 
    // this.headerService.updateProductDetail(product)
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})

export class AppComponent implements OnInit {


  constructor(
    private router: Router,
  ) {}
  
  ngOnInit() {

  }

  isDashboard(): boolean {
    return this.router.url.startsWith('/dashboard');
  }

  isErrorPage(): boolean {
    return this.router.url =='/errorPage';
  }
}


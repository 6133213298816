<!-- Remove the container if you want to extend the Footer to full width. -->


<footer id="footer" class="footer">



    <div class="container footer-top">
        <div class="row gy-4">
            <div class="col-lg-4 col-md-6 footer-about">
                <!-- <a href="index.html" class="d-flex align-items-center">
                    <span class="sitename">{{companyInfo.companyName}}
                    </span>
                </a> -->
                <h5 routerLink="/home" class=" text-uppercase"> {{companyInfo.companyName}}
                </h5>
                <div class="footer-contact pt-3">
                    <div *ngFor="let address of companyInfo.city">

                        <p><i class="fas fa-home mr-3"></i> {{address.cityName}}
                            {{address.cityAddress}} {{address.country}}
                            {{address.pinCode}}</p>
                        <!-- <p><i class="fas fa-home mr-3"></i> {{companyInfo.city[1].cityName}}
                        {{companyInfo.city[1].cityAddress}} {{companyInfo.city[1].country}}
                        {{companyInfo.city[1].pinCode}}</p> -->
                    </div>
                    <p><i class="fas fa-envelope mr-3"></i> {{companyInfo.website}}</p>
                    <p><strong>Email:</strong> <span>{{companyInfo.email}}</span></p>

                    <p><i class="fas fa-phone mr-3"></i> {{companyInfo.primaryMobile}}</p>
                </div>
            </div>

            <div class="col-lg-2 col-md-3 footer-links">
                <h4>Useful Links</h4>
                <ul>
                    <li><i class="bi bi-chevron-right"></i> <a routerLink="/home"
                            (click)="actionFooterTab('Home', $event)">Home</a></li>
                    <li><i class="bi bi-chevron-right"></i> <a routerLink="/about"
                            (click)="actionFooterTab('About', $event)">About us</a></li>
                    <li><i class="bi bi-chevron-right"></i> <a routerLink="/services"
                            (click)="actionFooterTab('Services', $event)">Services</a></li>
                    <li><i class="bi bi-chevron-right"></i> <a routerLink="/contact"
                            (click)="actionFooterTab('Contact Us', $event)">Contact Us</a></li>
                    <!-- <li><i class="bi bi-chevron-right"></i> <a routerLink="/services" (click)="actionFooterTab('Services', $event)">Terms of service</a></li> -->
                </ul>
            </div>

            <div class="col-lg-2 col-md-3 footer-links">
                <h4>Our Services</h4>
                <ul>
                    <!-- <li>
                        <i class="bi bi-chevron-right"></i> 
                        <div [routerLink]="['ourServices']" fragment="section1"> 
                            <a href="#section1">Jump to 'Section2' anchor </a> 
                        </div>
                    </li>
                    <li>
                        <i class="bi bi-chevron-right"></i> 
                        <div routerLink="ourServices" fragment="section2"> 
                            <a href="#section2">Jump to 'Section2' anchor </a> 
                        </div>
                    </li> -->
                    <li><i class="bi bi-chevron-right"></i> <a routerLink="developments">Developments</a></li>
                    <li><i class="bi bi-chevron-right"></i> <a routerLink="services">Trainings</a></li>
                    <li><i class="bi bi-chevron-right"></i> <a routerLink="productManagement">Product Management</a></li>
                    <li><i class="bi bi-chevron-right"></i> <a routerLink="partners">Our Partners</a></li>
                </ul>
            </div>

            <div class="col-lg-4 col-md-12">
                <h4>Follow Us</h4>
                <!-- <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p> -->
                <div class="social-links d-flex">
                    <!-- Facebook -->
                    <a class="btn btn-outline-light btn-floating m-1" href="{{companyInfo.fbLink}}" role="button"><i
                            class="fab fa-facebook-f"></i></a>


                    <!-- Google -->
                    <a class="btn btn-outline-light btn-floating m-1" href="{{companyInfo.city[1].googleMapLink}}"
                        role="button"><i class="fab fa-google"></i></a>

                    <!-- Instagram -->
                    <a class="btn btn-outline-light btn-floating m-1" href="{{companyInfo.instaLink}}" role="button"><i
                            class="fab fa-instagram"></i></a>


                </div>
            </div>

        </div>
    </div>

    <div class="container copyright text-center mt-4">
        <p>© <span>Copyright</span> <strong class="px-1 sitename">{{companyInfo.companyName}}</strong> <span>All Rights
                Reserved</span></p>
        <div class="credits">
            <!-- All the links in the footer should remain intact. -->
            <!-- You can delete the links only if you've purchased the pro version. -->
            <!-- Licensing information: https://bootstrapmade.com/license/ -->
            <!-- Purchase the pro version with working PHP/AJAX contact form: [buy-url] -->
            Designed by <a href="{{companyInfo.website}}">{{companyInfo.companyName}}</a>
        </div>
    </div>

</footer>
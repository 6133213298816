import { environment } from '../../environments/environment';

export class AppConstants {


}

export const SUCCESS_MSG_HTML: string = '<div class="alert alert-success" > Success! ';
export const ERROR_MSG_HTML: string = '<div class="alert alert-danger" > Error! ';
export const DIV: string = '</div>';
export const DELETE_CONFIRMATION: string = 'Are you sure you want to delete this record?';
export const REFRESH_CONFIRMATION: string = 'Do you want to refresh all fields?';
export const LOGOUT_CONFIRMATION: string = 'Are you sure you want to logout?';
export const UNAUTHENTICATED_MSG: string = 'Something went wrong. Unauthenticated.';
export const KEY_TOKEN: string = 'token';
export const KEY_BEARRER_TOKEN: string = 'BearerToken';
export const SERVER_DOWN: string = 'Server is down. Please try again later.';
export const CLIENT_SIDE_ERROR: string = 'Something went wrong. ';

// export const BASE_API_URL: string = "http://localhost:8080/api";
export const BASE_API_URL: string = environment.API_URL;
export const ROUTE_HOME: string = 'home';
export const ROUTE_SLASH_HOME: string = '/home';
export const ROUTE_MENU: string = 'menu';
export const ROUTE_PORTFOLIO: string = 'portfolio';
export const ROUTE_SERVICES: string = 'services';
export const ROUTE_PARTNERS: string = 'partners';
export const ROUTE_COURSES: string = 'courses';
export const ROUTE_DEVELOPMENTS: string = 'developments';
export const ROUTE_CITY: string = 'city';
export const ROUTE_COMPANY_INFO: string = 'companyInfo';
export const ROUTE_DASHBOARD: string = 'dashboard';
export const ROUTE_SLASH_DASHBOARD: string = '/dashboard';
export const ROUTE_SLASH_DASHBOARD_SLASH_TABS: string = '/dashboard/tabs/';
export const ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_DEVELOPMENTS: string = '/dashboard/tabs/developments';
export const ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_COURSES: string = '/dashboard/tabs/courses';
export const ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_MENU: string = '/dashboard/tabs/menu';
export const ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_PORTFOLIO: string = '/dashboard/tabs/portfolio';
export const ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_SERVICES: string = '/dashboard/tabs/services';
export const ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_PARTNERS: string = '/dashboard/tabs/partners';
export const ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_CITY: string = '/dashboard/tabs/city';
export const ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_COMPANYINFO: string = '/dashboard/tabs/companyInfo';
export const ROUTE_ID: string = 'id';
export const ROUTE_SLASH_LOGOUT: string = '/logout';
export const ROUTE_SLASH_TABLE_RECORD_SLASH: string = '/getTblRecords/';
export const ROUTE_LOGIN: string = 'login';
export const ROUTE_ERROR_PAGE: string = 'errorPage';


export const WEB_KEY: string = "padmtech";
export const KEY_MENU: string = "menu";
export const KEY_COMPANYINFO: string = "companyInfo";
export const KEY_CITY: string = "city";
export const KEY_PRODUCTLIST: string = "productList";
export const KEY_SERVICE: string = "serviceList";
export const KEY_COURSEDETAILS: string = "courseDetailList";
export const KEY_DEVDETAILS: string = "devDetail";
export const KEY_DEV: string = "dev";
export const KEY_PARTNERS: string = "partners";
export const KEY_IS_SHOW_ADD_BUTTON: string = "isShowAddButton";
// export const KEY_COURCEDETAIL: string = "serviceDetail";










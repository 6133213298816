export class CompanyInfo {
    id: number=0;
    company_name: string= "";
    logo: any;
    email: string= "";
    website: string= "";
    city_id: number=0;
    short_info: string= "";
    long_info: string= "";
    primary_mobile: string= "";
    secondary_mobile: string= "";
    insta_link: string= "";
    fb_link: string= "";
    date_modified!: Date;
}

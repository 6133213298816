import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorSharedServiceService {

  constructor() { }


  //BehaviorSubjects for getting error data
  private errorCodeSubject = new BehaviorSubject<any>(null);
  errorCode$ = this.errorCodeSubject.asObservable();

  private serverErrorCodeSubject = new BehaviorSubject<any>(null);
  serverErrorCode$ = this.serverErrorCodeSubject.asObservable();

  private errorMessageSubject = new BehaviorSubject<any>(null);
  errorMessage$ = this.errorMessageSubject.asObservable();

  

  updateErrorCode(value: any): void {
    this.errorCodeSubject.next(value);
  }

  updateServerErrorCode(value: any): void {
    this.serverErrorCodeSubject.next(value);
  }
  
  updateErrorMessage(value: any): void {
    this.errorMessageSubject.next(value);
  }

 
}

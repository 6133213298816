import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HeaderService } from "../servives/header.service";
import { KEY_BEARRER_TOKEN, KEY_TOKEN, ROUTE_DASHBOARD, ROUTE_HOME, ROUTE_SLASH_DASHBOARD, ROUTE_SLASH_DASHBOARD_SLASH_TABS, ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_DEVELOPMENTS } from '../utils/app-constants';
import { BehaviorSubject, catchError, map, Observable, of, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CommonMethodsService implements OnInit{
  
  constructor(
      private route: Router,
      private http: HttpClient
  ){}

  ngOnInit(){
  }

  //redirect home if unauthnticate
  redirectHome() {
      localStorage.removeItem(KEY_TOKEN);
      localStorage.removeItem(KEY_BEARRER_TOKEN);
      this.route.navigate([ROUTE_HOME]);
  }


  // after select, add, update and delete operation's response 
  //errorHandling function handles what to do
  public errorHandling(response: any) {
      if (response.status == 200) {
          alert(response.message)
      } else if (response.status == 401) { 
        alert(response.message)
      }
  }

  //alert after login
  public errorHandlingLogin(response: any) {
    if (response.status == 200) {
      localStorage.setItem(KEY_TOKEN, response.token);
      localStorage.setItem(KEY_BEARRER_TOKEN, `Bearer ${response.token}`),
      this.route.navigate([ROUTE_DASHBOARD]);

    }
    else if (response.status == 400 || 500) {
      alert(response.message);
    }
  }

  //alert after delete
  public errorHandlingDelete(response: any) {
    if (response.status == 200) {
        alert(response.message)
    } else if (response.status == 401) { 
      alert(response.message)
    }
  }


  //default image placeholder
  private defaultImageUrl = 'assets/default_image.png';

  //sets default image if error
  handleImageError(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.defaultImageUrl;
    imgElement.onerror = null; // Prevent infinite loop in case the default image is broken
  }


  //for image slider of portfolio on home
  validateImageUrl(url: string): Observable<boolean> {
    return this.http.head(url, { observe: 'response' }).pipe(
      map(response => response.status === 200),
      catchError(() => of(false)) // Return false if an error occurs
    );
  }

  //companyInfo date modified 
  private companyDateModifiedSubject = new BehaviorSubject<any>(null);
  companyDateModified$ = this.companyDateModifiedSubject.asObservable();
  updateCompanyDateModified(value: any): void {
    this.companyDateModifiedSubject.next(value);
  }
  //checks if companyInfo date modified 
  // private isCompanyDateModifiedSubject = new BehaviorSubject<any>(null);
  // isCompanyDateModified$ = this.isCompanyDateModifiedSubject.asObservable();
  // updateIsLocalDateModified(value: any): void {
  //   this.isCompanyDateModifiedSubject.next(value);
  // }
  private isCompanyDateModifiedSubject = new Subject<boolean>();
  isCompanyDateModified$ = this.isCompanyDateModifiedSubject.asObservable();
  updateIsLocalDateModified(value: boolean) {
    this.isCompanyDateModifiedSubject.next(value);
  }

  //companyInfo id 
  private companyInfoIdSubject = new BehaviorSubject<any>(null);
  companyInfoId$ = this.companyInfoIdSubject.asObservable();
  updateCompanyInfoId(value: any): void {
    this.companyInfoIdSubject.next(value);
  }

  

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Tables } from '../../common/common-tables';
import { CompanyInfo } from './company-info.model';
import { UserdataService } from '../../servives/userdata.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { KEY_COMPANYINFO, KEY_COURSEDETAILS, ROUTE_ID, ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_COMPANYINFO } from '../../utils/app-constants';
import { ImageUploadsComponent } from '../../directive/image-uploads/image-uploads.component';
import { LocalStorageService } from '../../servives/local-storage.service';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrl: './company-info.component.css'
})
export class CompanyInfoComponent implements OnInit {
  commonTables: Tables[] = [];
  companyInfoModel: any = new CompanyInfo();
  selectedFile: File | null = null;
  imagePreview:any;
  id = null;

  API_URL = environment.API_URL;
  imageUrl: any;


  companyName: any;
  email: any;
  website: any;
  cityId: any;
  shortInfo: any;
  longInfo: any;
  primaryMobile: any;
  secondaryMobile: any;
  instaLink: any;
  fbLink: any;

  //to access properties or methods from other components, assume this is parent 
  //component and make child referance of other component
  @ViewChild(ImageUploadsComponent)
  ImageUploadsComponentRef!: ImageUploadsComponent;

  constructor(
    private userData: UserdataService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorageService,
    protected commonMethodsService: CommonMethodsService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params[ROUTE_ID];
    if (this.id != null && this.id != undefined) {
      this.getOneData();
    }
  }

   //to set record's data in model to show in edit form when you 
    //click on edit(update) button
  getOneData() {
    this.userData.getOneCompanyInfo(this.id).subscribe((res: any) => {
      this.companyInfoModel = res;

      this.imageUrl = res.logo;
      this.companyName = res.company_name;
      this.email = res.email;
      this.website = res.website;
      this.cityId = res.city_id;
      this.shortInfo = res.short_info;
      this.longInfo = res.long_info;
      this.primaryMobile = res.primary_mobile;
      this.secondaryMobile = res.secondary_mobile;
      this.instaLink = res.insta_link;
      this.fbLink = res.fb_link;
    });
  }

  //check form is changed
  formChanged(): boolean {
    if (
      this.companyName !== this.companyInfoModel.company_name ||
      this.email !== this.companyInfoModel.email ||
      this.website !== this.companyInfoModel.website ||
      this.cityId !== this.companyInfoModel.city_id ||
      this.shortInfo !== this.companyInfoModel.short_info ||
      this.longInfo !== this.companyInfoModel.long_info ||
      this.primaryMobile !== this.companyInfoModel.primary_mobile ||
      this.secondaryMobile !== this.companyInfoModel.secondary_mobile ||
      this.instaLink !== this.companyInfoModel.insta_link ||
      this.fbLink !== this.companyInfoModel.fb_link ||
      this.selectedFile
    ) {
      return true;
    } else {
      return false;
    }
  }

  //common function for returning form data
  formData(isupdate: boolean) {
    //javascript method "FormData()" used to send form data
    const formData = new FormData();
    //in update method id is required but not in add method
    if(isupdate == true) {
      this.companyInfoModel.id = this.id;
      formData.append('id', this.companyInfoModel.id);
    }
    formData.append('company_name', this.companyInfoModel.company_name);
    formData.append('email', this.companyInfoModel.email);
    formData.append('website', this.companyInfoModel.website);
    formData.append('city_id', this.companyInfoModel.city_id);
    formData.append('short_info', this.companyInfoModel.short_info);
    formData.append('long_info', this.companyInfoModel.long_info);
    formData.append('primary_mobile', this.companyInfoModel.primary_mobile);
    formData.append('secondary_mobile', this.companyInfoModel.secondary_mobile);
    formData.append('insta_link', this.companyInfoModel.insta_link);
    formData.append('fb_link', this.companyInfoModel.fb_link);    
    if (this.selectedFile) {
      formData.append('logo', this.selectedFile, this.selectedFile.name);
    }
    return formData;
  }

  //to update record
  updateData() {
    //this.localStorage.removeItem(KEY_COMPANYINFO);
    this.selectedFile = this.ImageUploadsComponentRef.selectedFile;
    if(this.formChanged()) {
      this.userData
        .updateCompanyInfo(this.formData(true))
        .subscribe((response: any) => {
          this.commonMethodsService.errorHandling(response);
          this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_COMPANYINFO]);
        });
    }
  }

  //to add new record
  addData() {
    //this.localStorage.removeItem(KEY_COMPANYINFO);
    this.selectedFile = this.ImageUploadsComponentRef.selectedFile;
    if(this.selectedFile !== null) {
      this.userData.addCompanyInfo(this.formData(false)).subscribe((response: any) => {
        this.commonMethodsService.errorHandling(response);
        this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_COMPANYINFO]);
      });
    } else {
      alert("Picture is required!");
    }

  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { UserdataService } from '../../servives/userdata.service';
import { REFRESH_CONFIRMATION } from '../../utils/app-constants';
import { format, parseISO } from 'date-fns';
import { TabsComponent } from '../tabs/tabs.component';
import { DateTimeService } from '../../servives/date-time.service';
import { ContextServiceService } from '../../servives/context-service.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {

  //to access properties or methods from other components, assume this is parent 
  //component and make child referance of other component
  @ViewChild(TabsComponent)
  TabsComponentRef!: TabsComponent;

  companyDateModified: any = null;
  companyInfoId: any;

  constructor(
    protected commonMethodsService: CommonMethodsService,
    private userDateService: UserdataService,
    private dateTimeService: DateTimeService,
    private requestContext: ContextServiceService,
  )
  {}



  ngOnInit() {
    this.requestContext.setContext('DashboardComponent');
    //getting dateModified from components like  headers and tabs
    //normaly from headers
    //when edited or new added then from tabs
    this.commonMethodsService.companyDateModified$.subscribe((data) => {
      if(data != "" || null || {} ) {
        this.companyDateModified = this.dateTimeService.formatTo12Hour(data);
      }
    });

    this.commonMethodsService.companyInfoId$.subscribe((data) => {
      if(data != null || undefined ) {
        this.companyInfoId = data;
      }
    });
  }
  

  //updates date modified in company info to current timestamp
  refreshDateModified(): any {
    var confirmationPopup = confirm(REFRESH_CONFIRMATION);
    if (confirmationPopup) {
      //checks if companyInfo date modified
      //this.commonMethodsService.updateIsLocalDateModified(true);
      return this.userDateService.refreshDateModified(this.companyInfoId).subscribe((response: any) => {
        if(response.message == 'Company info is empty.') {
          alert(response.message);
        }
        if(response.status == 200 ) {
          //appends AmPm To Time
          this.companyDateModified = this.dateTimeService.formatTo12Hour(response.date_modified);
        } else {
          alert(JSON.stringify(response.message));
        }
      });
    }
  }
}

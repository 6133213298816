import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanyInfo } from '../../common/company-info';
import { HeaderService } from '../../servives/header.service';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_COMPANYINFO } from '../../utils/app-constants';
import { Router } from '@angular/router';
import { Contact } from './contact.model';
import { ApiCallService } from '../../servives/api-call.service';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css'
})

export class ContactComponent implements OnInit {

  serviceForm:any;
  

  contactObj = new Contact();
  successMsg: string = '';
  selectedFile: File | null = null;

  lat: number = 18.520459;
  lng: number = 73.773973;

  mapOptions: google.maps.MapOptions = {
    center: { lat: this.lat, lng: this.lng },
    zoom: 16
  }

  marker = {
    position: { lat: this.lat, lng: this.lng },
  }

  companyInfo: any = CompanyInfo

  mapLink = ""
  
  constructor(private headerService: HeaderService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private apiService: ApiCallService,

  ) {

  }
  ngOnInit() {


    var currentTab = this.router.url
    if (currentTab.toLowerCase().includes("contact")) {
      this.headerService.updateHeaderValue("Contact")
    }

    this.headerService.companyInfo
      .subscribe((companyInfodata: CompanyInfo) => {
        this.companyInfo = companyInfodata;
      });

    if (this.localStorageService.getObject(KEY_COMPANYINFO) != null) {
      var localCompanyInfo: any = CompanyInfo
      localCompanyInfo = this.localStorageService.getObject(KEY_COMPANYINFO);
      this.companyInfo = localCompanyInfo;
    }

  }

  @ViewChild('contactForm') input: any;

  //function to check file is selected or not
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
    }
  }

  addContact(form:NgForm)
  {
    if (form.invalid) {
      form.resetForm();
      return;
    }
    //javascript method "FormData()" used to send form data
    const formData = new FormData();
    formData.append('name', this.contactObj.name);
    formData.append('email', this.contactObj.email);
    formData.append('mobile_number', this.contactObj.mobile);
    formData.append('subject', this.contactObj.subject);
    formData.append('message', this.contactObj.message);
    if (this.selectedFile) {
      formData.append('attachment', this.selectedFile, this.selectedFile.name);
    }

    //addContacts() adds/post data to server
    this.apiService.addContacts(formData).subscribe((response: any) =>{
      if(response.status == 200 || 400 || 500)
      {
        alert(response.message);
      }
    });
    //reset method resets the form
    form.resetForm();
  }


  
  

}

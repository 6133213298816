import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {

  constructor() { }

  formatTo12Hour(dateTime: string): string {
    const date = new Date(dateTime);

    if (isNaN(date.getTime())) {
      throw new Error('Invalid date format');
    }

    // Extract the full date
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = date.getDate().toString().padStart(2, '0');

    // Extract the time
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Format time
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    // Combine date and time
    return `${year}-${month}-${day} ${formattedHours}:${formattedMinutes}:${formattedSeconds} ${period}`;
  }
}

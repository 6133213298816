import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { ServicesComponent } from './components/services/services.component';
import { GoogleMapsModule } from '@angular/google-maps'

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgImageSliderModule } from 'ng-image-slider';
import { PortfolioDetailComponent } from './components/portfolio-detail/portfolio-detail.component';
import { ServicDetailComponent } from './components/servic-detail/servic-detail.component';
import { HeaderComponent } from './components/header/header.component';
import { ProjectManagementComponent } from './components/project-management/project-management.component';
import { DevelopmentsComponent } from './components/developments/developments.component';
import { DevDetailsComponent } from './components/dev-details/dev-details.component';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { PartnersComponent } from './components/partners/partners.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { NgxSpinnerModule } from "ngx-spinner";
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { SignUpComponent } from './users/sign-up/sign-up.component';
import { LoginComponent } from './users/login/login.component';
import { MatButtonModule } from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import { DashboardComponent } from './users/dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { ProfileComponent } from './users/profile/profile.component';
import {MatListModule} from '@angular/material/list';
import { SidebarComponent } from './users/sidebar/sidebar.component';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import {MatTreeModule} from '@angular/material/tree';
import { MenuComponent } from './addOrUpdate/menu/menu.component';
import { TabsComponent } from './users/tabs/tabs.component';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import { ServiceComponent } from './addOrUpdate/service/service.component';
import { DevsComponent } from './addOrUpdate/devs/devs.component';
import { PortFolioComponent } from './addOrUpdate/port-folio/port-folio.component';
import { PartnerComponent } from './addOrUpdate/partner/partner.component';
import { CourseComponent } from './addOrUpdate/course/course.component';
import { CityComponent } from './addOrUpdate/city/city.component';
import { CompanyInfoComponent } from './addOrUpdate/company-info/company-info.component';
import { SetPasswordComponent } from './users/set-password/set-password.component' 
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { ImageUploadsComponent } from './directive/image-uploads/image-uploads.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle'; 

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    PortfolioComponent,
    ServicesComponent,
    PortfolioDetailComponent,
    ServicDetailComponent,
    ProjectManagementComponent,
    DevelopmentsComponent,
    DevDetailsComponent,
    PartnersComponent,
    SpinnerComponent,
    SignUpComponent,
    LoginComponent,
    DashboardComponent,
    ProfileComponent,
    SidebarComponent,
    MenuComponent,
    TabsComponent,
    ServiceComponent,
    DevsComponent,
    PortFolioComponent,
    PartnerComponent,
    CourseComponent,
    CityComponent,
    CompanyInfoComponent,
    SetPasswordComponent,
    ImageUploadsComponent,
    ErrorPageComponent
  ],
  imports: [
    NgxMatFileInputModule,
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    HttpClientModule,
    NgImageSliderModule,
    MdbCheckboxModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    NgxSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MdbDropdownModule, MdbRippleModule,
    MatTreeModule,
    MatRadioModule,
    MatButtonToggleModule
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MdbDropdownModule, MdbRippleModule,
    MatTreeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  
  providers: [
    AuthGuard, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,  
      useValue: { color: 'accent' },  
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

interface NgxSpinnerConfig {
  type?: string;
}
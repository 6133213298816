<nav id="mainNavbar" class="navbar navbar-expand-sm navbar-light">
  <div class="container-fluid">
    <div class="leftside">

      <img routerLink="/home" (click)="actionHeaderTab('Home', $event)" src="{{API_URL}}/{{companyInfo.logo}}" (error)="onImageError($event)" class="imglogo"
        id="logo" />
      <h4 class="companyName">{{companyInfo.companyName || padmtech}}</h4>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
        (click)="collapsed = !this.collapsed">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    
      <div class="rightside">

        <div id="navbarSupportedContent" [ngClass]="{ collapse: collapsed, 'navbar-collapse': true }">

          @if(!isDashboard()) {
  
          <div  *ngFor="let menuObj of menu">
  
  
            <ul class="navbar-nav ml-auto">
  
              <li  class="nav-item">@if (menuObj.isActive == true) {<a routerLink={{menuObj.routeLink}} (click)="actionHeaderTab(menuObj.menuName, $event)"
                  [ngClass]="menuObj.menuName === activeTab ? 'menuactive text-white font-weight-bold' : 'menuinactive text-white'"
                  class="nav-link menuinactive">{{menuObj.menuName}} </a>}</li>
            </ul>
          </div>
        }
        <!--@else if(isDashboard()){
          <button href="#" *ngIf="tokenVal" (click)="logoutUser()" class="logout-btn mt-3" ><mat-icon class="logout-icon">logout</mat-icon> Logout</button>
        } -->
        </div> 
      </div>   
  </div>
</nav>
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserdataService } from './servives/userdata.service';
import { KEY_BEARRER_TOKEN, KEY_TOKEN, ROUTE_DASHBOARD, ROUTE_HOME } from './utils/app-constants';

@Injectable({
  providedIn: 'root',
})

// export const authGuard: CanActivateFn = (route, state) => {
//   return true;
// };
export class AuthGuard implements CanActivate {
  token: any;
  bearerToken: any;

  constructor(private route: Router, private userdata: UserdataService) {}

  //sends token to server if valid then sends status 200 back
  validateToken() {
    this.userdata.validateToken().subscribe((response: any) => {
      if (!(response.status == 200)) {
        this.route.navigate([ROUTE_HOME]);
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree 
  {

    this.validateToken();
    
    this.token = localStorage.getItem(KEY_TOKEN);
    this.bearerToken = localStorage.getItem(KEY_BEARRER_TOKEN);

    if (!this.token || !this.bearerToken) {
      this.route.navigate([ROUTE_HOME]);
    }
    return true;
  }
}

<!-- Services Section -->
<section id="services" class="services section light-background">
  <!-- Section Title -->
  <div class="container section-title" data-aos="fade-up">
    <h2>Services</h2>
  </div>
  <!-- End Section Title -->

  <div class="container">
    <div class="">
      <ul class="cards">
        <ng-container *ngFor="let courseObj of courseDetailList">
          <li
            class="cards__item"
            *ngIf="courseObj.isActive"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="card" (click)="detailPage1(courseObj)">
              <div class="">
                <img
                  src="{{ API_URL }}/{{ courseObj.courseLogo }}"
                  (error)="commonMethodsService.handleImageError($event)"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div
                class="card__content"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="card__title">{{ courseObj.courseName }}</div>
                <!-- <p class="card__text">This is the shorthand for flex-grow, flex-shrink and flex-basis combined. The second and third parameters (flex-shrink and flex-basis) are optional. Default is 0 1 auto. </p> -->
                <p class="divellipsis">{{ courseObj.shortInfo }}</p>
                <!-- <button class="btn btn--block card__btn">Button</button> -->
                <a (click)="detailPage1(courseObj)" class="read-more"
                  ><span>Read More</span><i class="bi bi-arrow-right"></i
                ></a>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>

      <!-- <div class="col-xl-3 col-sm-1" *ngFor="let courseObj of courseDetailList" data-aos="fade-up"
                data-aos-delay="100">
                <div class="service-item position-relative" (click)="detailPage1(courseObj)">
                    <div class="icon"><i class="bi bi-activity icon"></i></div>
                    <div class="entry-img">
                        <img src="{{courseObj.courseLogo}}" class="img-fluid" alt="">

                    </div>
                    <h4><a class="stretched-link">{{courseObj.courseName}}</a></h4>

                    <div class="col-lg-50" data-aos="fade-up" data-aos-delay="200">
                        <p class="divellipsis">{{courseObj.shortInfo}}</p>
                        <a (click)="detailPage1(courseObj)" class="read-more"><span>Read More</span><i
                                class="bi bi-arrow-right"></i></a>

                    </div>
                </div>
            </div> -->
    </div>
    <br />
    <br />
    <div class="row">
      <div
        class="justify-content-start text-start"
        *ngFor="let servicelistObj of serviceList"
      >
        <div class="col-1"></div>
        <div
          class="content order-2 order-lg-1 text-dark"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <h5>
            <strong>{{ servicelistObj.serviceName }}: </strong>
            <span>{{ servicelistObj.shortInfo }} </span>
          </h5>
          <br />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- /Services Section -->

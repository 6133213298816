export class Courses {

    constructor(public id: number,
        public courseName: string,
        public courseLogo: string,
        public shortInfo: string,
        public isActive: boolean,

        public courseDuration: string,
        public courseTime: string,
        public courseMode: string,
        public contact: string,
    ) {

    }
}

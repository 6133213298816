import { Component, OnInit, ViewChild } from '@angular/core';
import { UserdataService } from '../../servives/userdata.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../servives/header.service';
import { DIV, ERROR_MSG_HTML, KEY_BEARRER_TOKEN, KEY_TOKEN, LOGOUT_CONFIRMATION, ROUTE_CITY, ROUTE_COMPANY_INFO, ROUTE_COURSES, ROUTE_DEVELOPMENTS, ROUTE_HOME, ROUTE_MENU, ROUTE_PARTNERS, ROUTE_PORTFOLIO, ROUTE_SERVICES, ROUTE_SLASH_DASHBOARD, ROUTE_SLASH_DASHBOARD_SLASH_TABS } from '../../utils/app-constants';
import { TabsComponent } from '../tabs/tabs.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent implements OnInit {

  collapsed = true;

  errorOrSuccessMsg: string = '';
  selectedRouteValue: string = '';
  clickedRoute: any;
  tokenVal = localStorage.getItem(KEY_TOKEN);

  
 

  constructor(
    private userdata: UserdataService,
    private route: Router,
    private headerService: HeaderService,
    private router: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
 
  }
  
  //gets table data
  navigate(event: Event): void {
    this.clickedRoute = event.target as HTMLSelectElement;
    
    this.selectedRouteValue = this.clickedRoute.value;
    
    if (this.selectedRouteValue == ROUTE_MENU) {
      this.route.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS, this.selectedRouteValue])
    } else if(this.selectedRouteValue == ROUTE_PORTFOLIO) {
      this.route.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS, this.selectedRouteValue])
    
    } else if(this.selectedRouteValue == ROUTE_SERVICES) {
      this.route.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS, this.selectedRouteValue])
    
    } else if(this.selectedRouteValue == ROUTE_PARTNERS) {
      this.route.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS, this.selectedRouteValue])
    
    } else if(this.selectedRouteValue == ROUTE_COURSES) {
      this.route.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS, this.selectedRouteValue])
    
    } else if(this.selectedRouteValue == ROUTE_DEVELOPMENTS) {
      this.route.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS, this.selectedRouteValue])
    
    } else if(this.selectedRouteValue == ROUTE_CITY) {
      this.route.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS, this.selectedRouteValue])
    
    } else if(this.selectedRouteValue == ROUTE_COMPANY_INFO) {
      this.route.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS, this.selectedRouteValue])
    } else if(this.selectedRouteValue == '') {
      //this.route.navigate([ROUTE_SLASH_DASHBOARD])
    }
    this.headerService.updateTableDetail(this.selectedRouteValue);
  }
  

  

  logoutUser() {
    var confirmation = confirm(LOGOUT_CONFIRMATION);
    if (confirmation) {
      
      this.userdata.logoutUser().subscribe((response: any) => {
        if (response.status == 200 || 401) {
          localStorage.removeItem(KEY_TOKEN);
          localStorage.removeItem(KEY_BEARRER_TOKEN);
          this.route.navigate([ROUTE_HOME]);
        }

      });
    }

  }


}

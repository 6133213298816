import { Component, OnInit, ViewChild } from '@angular/core';
import { Tables } from '../../common/common-tables';
import { PortFolio } from './port-folio.model';
import { UserdataService } from '../../servives/userdata.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { KEY_PRODUCTLIST, ROUTE_ID, ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_PORTFOLIO } from '../../utils/app-constants';
import { ImageUploadsComponent } from '../../directive/image-uploads/image-uploads.component';
import { LocalStorageService } from '../../servives/local-storage.service';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-port-folio',
  templateUrl: './port-folio.component.html',
  styleUrl: './port-folio.component.css'
})
export class PortFolioComponent implements OnInit {
  commonTables: Tables[] = [];
  portFolioModel: any = new PortFolio();
  selectedFile: File | null = null;
  imagePreview:any;
  id = null;
  is_active: any;

  API_URL = environment.API_URL;
  imageUrl: any;

  clientName: any;
  isActive: any;
  clientCategory: any;
  shortInfo: any;

  //to access properties or methods from other components, assume this is parent 
  //component and make child referance of other component
  @ViewChild(ImageUploadsComponent)
  ImageUploadsComponentRef!: ImageUploadsComponent;

  constructor(
    private userData: UserdataService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorageService,
    protected commonMethodsService: CommonMethodsService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params[ROUTE_ID];

    //if id is not null
    if (this.id != null && this.id != undefined) {
      this.getOneData();
    }
  }

  //to set record's data in model to show in edit form when you click on edit(update) button
  getOneData() {
    this.userData.getOnePortfolio(this.id).subscribe((res: any) => {
      this.portFolioModel = res;

      this.imageUrl = res.client_logo;

      this.clientName = res.client_name;
      this.isActive = res.is_active;
      this.clientCategory = res.client_category;
      this.shortInfo = res.short_info;
    });
  }

  //check form is changed
  formChanged(): boolean {
    if (
      this.clientName !== this.portFolioModel.client_name ||
      this.isActive !== this.portFolioModel.is_active ||
      this.clientCategory !== this.portFolioModel.client_category ||
      this.shortInfo !== this.portFolioModel.short_info ||
      this.selectedFile
    ) {
      return true;
    } else {
      return false;
    }
  }

  //common function for returning form data
  formData(isupdate: boolean) {
    //javascript method "FormData()" used to send form data
    const formData = new FormData();
    //in update method id is required but not in add method
    if(isupdate == true) {
      this.portFolioModel.id = this.id;
      formData.append('id', this.portFolioModel.id);
    }
    
    this.is_active = this.portFolioModel.is_active; 
    formData.append('client_name', this.portFolioModel.client_name);
    formData.append('client_category', this.portFolioModel.client_category);
    formData.append('short_info', this.portFolioModel.short_info);
    formData.append('is_active', this.is_active);
    
    if (this.selectedFile) {
      formData.append('client_logo', this.selectedFile, this.selectedFile.name);
    }
    return formData;
  }

  //to update record
  updateData() {
    this.localStorage.removeItem(KEY_PRODUCTLIST);
    this.selectedFile = this.ImageUploadsComponentRef.selectedFile;
    if(this.formChanged()) {
      //api call
      this.userData
        .updatePortfolio(this.formData(true))
        .subscribe((response: any) => {
          this.commonMethodsService.errorHandling(response);
          this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_PORTFOLIO]);
        });
    }
  }

  //if checkbox(switch) is checked is_active status is true otherwise false
  onCheckboxChange(event: any) {
    const checkbox = event.currentTarget as HTMLInputElement;
    this.portFolioModel.is_active = checkbox.checked ? true : false;
  }

  //to add new record
  addData() {
    //this.localStorage.removeItem(KEY_PRODUCTLIST);
    this.selectedFile = this.ImageUploadsComponentRef.selectedFile;
    if(this.selectedFile !== null) {
      this.userData.addPortfolio(this.formData(false)).subscribe((response: any) => {
        this.commonMethodsService.errorHandling(response);
        this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_PORTFOLIO]);
      });
    } else {
      alert("Picture is required!");
    }
  }
 
}

<section id="services" class="services section light-background">
  <!-- Section Title -->
  <div class="container section-title" data-aos="fade-up">
    <h2>Developments</h2>
  </div>
  <!-- End Section Title -->

  <div class="container">
    <div>
      <ul class="cards">
        <ng-container *ngFor="let devObj of devs">
          <li
            *ngIf="devObj.isActive"
            class="cards__item"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div class="card" (click)="detailPage(devObj)">
              <div class="">
                <img
                  src="{{ API_URL }}/{{ devObj.devLogo }}"
                  (error)="commonMethodsService.handleImageError($event)"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div
                class="card__content"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="card__title">{{ devObj.devName }}</div>
                <!-- <p class="card__text">This is the shorthand for flex-grow, flex-shrink and flex-basis combined. The second and third parameters (flex-shrink and flex-basis) are optional. Default is 0 1 auto. </p> -->
                <p class="divellipsis">{{ devObj.shortInfo }}</p>
                <!-- <button class="btn btn--block card__btn">Button</button> -->
                <a (click)="detailPage(devObj)" class="read-more"
                  ><span>Read More</span><i class="bi bi-arrow-right"></i
                ></a>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</section>

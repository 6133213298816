@if(updateFlag==false){
<mat-card>
  <mat-card-content>
    <div class="bootstrap-wraper">
      <div class="container">
        <div class="container text-center">
          <img
            src="{{ API_URL }}/{{ imageUrl }}"
            alt="profile-picture"
            class="profile-image"
            id="profilePic"
          />
          <h1 class="mt20">
            {{ profileModel.firstname }} {{ profileModel.lastname }}
          </h1>
        </div>
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <!-- table details -->
            <table class="table text-center">
              <tr>
                <td>User Name</td>
                <td>{{ profileModel.name }}</td>
              </tr>
              <tr>
                <td>Firstname</td>
                <td>{{ profileModel.firstname }}</td>
              </tr>
              <tr>
                <td>Lastname</td>
                <td>{{ profileModel.lastname }}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{{ profileModel.email }}</td>
              </tr>
              <tr>
                <td>Contact</td>
                <td>{{ profileModel.contact }}</td>
              </tr>
              <tr>
                <td>Is Active</td>
                <!-- <td>{{profileModel.is_active}}</td> -->
                <div class="full-width" appearance="outline">
                  <label class="switch mt-2">
                    <input type="checkbox" [checked]="profileModel.is_active" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions class="text-center">
    <button mat-raised-button color="primary" (click)="updateFlagTrue(true)">
      Update Profile
    </button>
  </mat-card-actions> 
</mat-card>} 
@if(updateFlag==true) {
<mat-card>
  <mat-card-content>
    <div class="bootstrap-wraper">
      <div class="container">
        <div class="row ml20 mb20">
          <div class="col-md-6 offset-md-3">
            <mat-card class="mt20 mb20" appearance="outlined" color="bule">
              <mat-card-content>
                <div class="container text-center">
                  <img src="{{ API_URL }}/{{ imageUrl }}" alt="logo" class="imglogo" />
                </div>

                <h3 class="text-center">Profile Update Form</h3>

                <form #form="ngForm" (ngSubmit)="updateProfile()">
                  <!-- Your Name -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Your Name</mat-label>
                    <input
                      #name="ngModel"
                      type="text"
                      class="form-control"
                      required
                      minlength="2"
                      maxlength="20"
                      name="name"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="profileModel.name"
                    />
                    <mat-hint>Minimum length 2</mat-hint>
                    <span
                      *ngIf="name.invalid && name.touched && !form.submitted"
                      class="validationAlertColor"
                    >
                      <span *ngIf="name.errors?.['required']"
                        >Name is required.</span
                      >
                      <span *ngIf="name.errors?.['minlength']"
                        >Name must be at least 2 and at most 20 characters
                        long.</span
                      >
                    </span>
                  </mat-form-field>
                  <!-- first Name -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input
                      #firstname="ngModel"
                      type="text"
                      class="form-control"
                      required
                      minlength="2"
                      maxlength="20"
                      name="firstname"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="profileModel.firstname"
                    />
                    <mat-hint>Minimum length 2</mat-hint>
                    <span
                      *ngIf="
                        firstname.invalid &&
                        firstname.touched &&
                        !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="firstname.errors?.['required']"
                        >firstname is required.</span
                      >
                      <span *ngIf="firstname.errors?.['minlength']"
                        >firstname must be at least 2 and at most 20 characters
                        long.</span
                      >
                    </span>
                  </mat-form-field>
                  <!-- Last Name -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input
                      #lastname="ngModel"
                      type="text"
                      class="form-control"
                      required
                      minlength="2"
                      maxlength="20"
                      name="lastname"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="profileModel.lastname"
                    />
                    <mat-hint>Minimum length 2</mat-hint>
                    <span
                      *ngIf="
                        lastname.invalid && lastname.touched && !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="lastname.errors?.['required']"
                        >lastname is required.</span
                      >
                      <span *ngIf="lastname.errors?.['minlength']"
                        >lastname must be at least 2 and at most 20 characters
                        long.</span
                      >
                    </span>
                  </mat-form-field>
                  <!-- Contact -->

                  <!-- <span class="country-code mb-4 pb-3">+91</span> -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label class="pl-5"> Contact</mat-label>
                    <div class="inlineElements">
                      <span class="country-code">+91</span>
                      <input
                        #contact="ngModel"
                        type="text"
                        class="phone-number-box"
                        required
                        pattern="\d{10}$"
                        name="contact"
                        matInput
                        placeholder="Enter hear"
                        [(ngModel)]="profileModel.contact"
                      />
                    </div>
                    <mat-hint>Write contact like '6375072382'</mat-hint>
                    <span
                      *ngIf="
                        contact.invalid && contact.touched && !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="contact.errors?.['required']"
                        >contact is required.</span
                      >
                      <span *ngIf="contact.errors?.['pattern']"
                        >Please enter 10 digit number.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- is active -->
                  <div class="full-width mb20" appearance="outline">
                    <mat-label class="mr-3">Check It For Active</mat-label>
                    <label class="switch">
                      <input
                        type="checkbox"
                        [checked]="profileModel.is_active"
                        (change)="onCheckboxChange($event)"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>

                  <!-- Profile pic -->
                  <!-- ViewChild -->
                  <app-image-uploads></app-image-uploads>

                  <div class="full-width mb20" appearance="outline">
                    <span *ngIf="form.invalid" class="validationAlertColor"
                      >Please enter required fields.</span
                    >
                  </div>

                  <div class="container text-center">
                    <button
                      mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="form.invalid"
                    >
                      Update
                    </button>

                    <button
                      class="mx-3"
                      mat-raised-button
                      color="primary"
                      (click)="updateFlagTrue(false)"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

}

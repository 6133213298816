import { Component, OnInit } from '@angular/core';
import { Tables } from '../../common/common-tables';
import { City } from './city.model';
import { UserdataService } from '../../servives/userdata.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { KEY_CITY, ROUTE_ID, ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_CITY } from '../../utils/app-constants';
import { LocalStorageService } from '../../servives/local-storage.service';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrl: './city.component.css'
})
export class CityComponent implements OnInit {
  commonTables: Tables[] = [];
  cityModel: any = new City();
  target: string = '';
  cid = null;

  cityName: any;
  cityAddress: any;
  pinCode: any;
  country: any;
  mobile: any;
  googleMapLink: any;
  latitude: any;
  longitude: any;

  constructor(
    private userData: UserdataService,
    private route: ActivatedRoute,
    private router: Router,
    private commonMethods: CommonMethodsService,
    private localStorage: LocalStorageService,
  ) {}

  ngOnInit() {
    this.cid = this.route.snapshot.params[ROUTE_ID];
    if (this.cid != null && this.cid != undefined) {
      this.getOneData();
    }
  }

  //to set record's data in model to show in edit form when you 
    //click on edit(update) button
  getOneData() {
    this.userData.getOneCity(this.cid).subscribe((res: any) => {
      this.cityModel = res;

      this.cityName = res.city_name;
      this.cityAddress = res.city_address;
      this.pinCode = res.pin_code;
      this.country = res.country;
      this.mobile = res.mobile;
      this.googleMapLink = res.google_map_link;
      this.latitude = res.latitude;
      this.longitude = res.longitude;
    });
  }

  //check form is changed
  formChanged(): boolean {
    if (
      this.cityName !== this.cityModel.city_name ||
      this.cityAddress !== this.cityModel.city_address ||
      this.pinCode !== this.cityModel.pin_code ||
      this.country !== this.cityModel.country ||
      this.mobile !== this.cityModel.mobile ||
      this.googleMapLink !== this.cityModel.google_map_link ||
      this.latitude !== this.cityModel.latitude ||
      this.longitude !== this.cityModel.longitude
    ) {
      return true;
    } else {
      return false;
    }
  }

  //to update record
  updateData() {
    //this.localStorage.removeItem(KEY_CITY);
    if(this.formChanged()) {
      this.userData
        .updateCity( this.cityModel)
        .subscribe((response: any) => {
          this.commonMethods.errorHandling(response);
          this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_CITY]);
        });
    }
  }

  //to add new record
  addData() {
    this.localStorage.removeItem(KEY_CITY);
    this.userData.addCity(this.cityModel).subscribe((response: any) => {
      this.commonMethods.errorHandling(response);
      this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_CITY]);
    });
  }

}

import { Injectable } from '@angular/core';
import { KEY_PRODUCTLIST, KEY_COURSEDETAILS, KEY_DEVDETAILS } from '../utils/app-constants';
import { PortfolioList } from '../common/portfolio-list';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  localStorage: Storage = sessionStorage

  constructor() { }

  setItem(key: string, value: any) {
    this.localStorage.setItem(key, value);
  }

  getItem(key: string): any {
    return this.localStorage.getItem(key);
  }

  setBool(key: string, value: boolean) {
    this.localStorage.setItem(key, String(value));
  }

  getBool(key: string): boolean {
    return this.localStorage.getItem(key) === 'true';
  }

  setObject(key: string, value: any) {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  getObject(key: string): any {
    var data = JSON.parse(this.localStorage.getItem(key)!)
    return data
  }

  getProductById(id: number): any {
    var localProductList: PortfolioList[] = []
    localProductList = this.getObject(KEY_PRODUCTLIST);

    let findedData = localProductList.find(i => i.id == id);
    if (typeof findedData === 'undefined') {
      return null;
    }
    return findedData;
  }

  getServiceDetailById(id: number): any {
    var localProductList: PortfolioList[] = []
    localProductList = this.getObject(KEY_COURSEDETAILS);
    let findedData = localProductList.find(i => i.id == id);

    if (typeof findedData === 'undefined') {
      return null;
    }
    return findedData;
  }

  getDevDetailById(id: number): any {
    var localProductList: PortfolioList[] = []
    localProductList = this.getObject(KEY_DEVDETAILS);
    let findedData = localProductList.find(i => i.id == id);

    if (typeof findedData === 'undefined') {
      return null;
    }
    return findedData;
  }

  clearSession() {
    this.localStorage.clear();
  }

  removeItem(token:any) {
    this.localStorage.removeItem(token);
  }

}

<!-- Parent component's template -->
<!-- <app-set-password></app-set-password> -->
<!-- Profile picture -->
<div class="col-md-12">
  <label for="subject-field" class="pb-2">Picture</label>
  <input
    #pic="ngModel"
    accept=".jpg, .jpeg, .png"
    (change)="onImageUpload($event)"
    type="file"
    class="form-control bborder"
    name="pic"
    [(ngModel)]="imageUploadsModel.profilePic"
    id="profilePic-field"
    required
  />
  <span
    *ngIf="pic.invalid && pic.touched"
    class="validationAlertColor"
    >
    <span *ngIf="pic.errors?.['accept']">Please choose profile picture of type .jpg, .jpeg, .png only.</span>
  </span>
</div>
<!-- Profile picture preview -->
<div class="col-sm-7">
  <div style="float: right">
    <img
      [src]="imagePreview"
      style="width: 700px; margin-top: 30px"
      class="img-thumbnail mb-lg-4"
      alt="..."
      *ngIf="imagePreview"
    />
  </div>
</div>
<main class="main">
  <!-- Hero Section -->
  <section id="hero" class="hero section dark-background">
    <div class="container">
      <div class="row gy-4">
        <div
          class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center"
          data-aos="zoom-out"
        >
          <h1>Better Solutions For Your Business</h1>
          <p>We are not a makers....We are Creators</p>
          <div class="d-flex">
            <a href="#about" class="btn-get-started">Get Started</a>
            <a class="glightbox btn-watch-video d-flex align-items-center"
              ><i class="bi bi-play-circle"></i><span>Watch Video</span></a
            >
          </div>
        </div>
        <div
          class="col-lg-6 order-1 order-lg-2 hero-img"
          data-aos="zoom-out"
          data-aos-delay="200"
        >
          <img
            src="assets/img/hero-img.png"
            class="img-fluid animated"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
  <div style="padding: 1%">
    <div style="height: 12%; background-color: #d9dfeb; align-content: center">
      <ng-image-slider
        class="homeSlider"
        [imageSize]="{ width: '12%', height: '10%', space: 3 }"
        [animationSpeed]="3"
        [slideImage]="1"
        [autoSlide]="true"
        [infinite]="true"
        [images]="imageObject"
        #nav
      >
      </ng-image-slider>
    </div>

    <!-- <div class="">
      <mdb-carousel [controls]="true" [indicators]="true" [animation]="'fade'">
        <mdb-carousel-item>
          <img
            src="https://mdbootstrap.com/img/Photos/Slides/img%20(15).webp"
            class="d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-none d-md-block">
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </div>
        </mdb-carousel-item>
        <mdb-carousel-item>
          <img
            src="https://mdbootstrap.com/img/Photos/Slides/img%20(22).webp"
            class="d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-none d-md-block">
            <h5>Second slide label</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </mdb-carousel-item>
        <mdb-carousel-item>
          <img
            src="https://mdbootstrap.com/img/Photos/Slides/img%20(23).webp"
            class="d-block w-100"
            alt="..."
          />
          <div class="carousel-caption d-none d-md-block">
            <h5>Third slide label</h5>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div> -->
  </div>
</main>

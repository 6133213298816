<div class="bootstrap-wraper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2">
        <app-sidebar></app-sidebar>
      </div>
      <div class="col-md-10">
        <section class="dashboardHome">
          <div class="container section-title" data-aos="fade-up">
            <h2 class="text-center mt20">Welcome To Dashboard!</h2>
          </div>

          <!-- Separate Section on the Right -->
          
            <div class="m-2" style="text-align: end">
              <div class="companyDateModified">
                <button class="btn btn-primary" (click)="refreshDateModified()"
                  >Refresh</button
                >
                <h6 *ngIf="companyDateModified" class="mt-3">Last updated: {{companyDateModified}}</h6>  
              </div>
            </div>
            <hr />

          <div class="mt-3" data-aos="fade-up" data-aos-delay="200">
            <p>
              Padmtech provides a broad range of web design services - from
              complex web applications, mobile applications, search engine
              optimization (SEO) services to responsive and static websites.
              More and more people have started using mobile phones to access
              websites and use apps for everything ranging from e-commerce
              shopping to lifestyle management. We offer mobile app development
              services for Android and iOS mobile phones. Since we are into web
              design, we also ensure that the mobile app has extremely
              attractive UI / UX design, and that the app is cross platform
              compatible.
            </p>
          </div>
        </section>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Partners } from '../../common/partners';
import { Router } from '@angular/router';
import { ApiCallService } from '../../servives/api-call.service';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_PARTNERS } from '../../utils/app-constants';
import { HeaderService } from '../../servives/header.service';
import { environment } from '../../../environments/environment.development';
import { CommonMethodsService } from '../../servives/common-methods.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrl: './partners.component.css'
})
export class PartnersComponent implements OnInit {
  
  ptns: Partners[] = [];
  API_URL = environment.API_URL;

  show = false
  constructor(private routes: Router,
    private apiCallService: ApiCallService,
    private headerService: HeaderService,
    private localStorage: LocalStorageService,
    private router: Router,
    protected commonMethodsService: CommonMethodsService
  ) { }

  ngOnInit() {
    //checks if companyInfo date modified 
    this.isCompanyDateModified();
    
    var currentTab = this.router.url
    if (currentTab.toLowerCase().includes("partners")) {
      this.headerService.updateHeaderValue("Partners")
    }

    var ptnsListObj = JSON.parse(this.localStorage.getItem(KEY_PARTNERS))

    if (ptnsListObj == null) {
      //getting partners list
      this.getPtnsList()
      // this.serviceList = WebData.service
      // this.localStorage.setObject(KEY_SERVICE, this.serviceList)
    } else {
      this.ptns = ptnsListObj
    } 

  }

  //checks if companyInfo date modified if yes then calls List api
  isCompanyDateModified() {
    this.commonMethodsService.isCompanyDateModified$.subscribe((data) => {
        if(data == true) {
          this.getPtnsList();
          console.log("isCompanyDateChanged: "+data);
        }
    });
  }

  // Function to retrieve ptnLogo by id
  getPtnLogoById(id: number): string | null {
    const partner = this.ptns.find(ptn => ptn.id === id);
    return partner ? partner.ptnLogo : null; // Return ptnLogo if partner is found, otherwise null
  }

  //partners list
  getPtnsList() {
    this.apiCallService.getPtnListAPI().subscribe({
      next: (data: Partners[]) => {
        this.ptns = data
        this.localStorage.setObject(KEY_PARTNERS, this.ptns)

      },
      error: (err: any) => {
        
      },
      complete() {

      },

    })
  }

}

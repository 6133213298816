export class City {
    city_name: string= "";
    city_address: string= "";
    pin_code: string= "";
    country: string= "";
    mobile: string= "";
    google_map_link: string= "";
    latitude: string= "";
    longitude: string= "";
}

import { Component, OnInit } from '@angular/core';
import {KEY_MENU, ROUTE_ID, ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_MENU } from '../../utils/app-constants';
import { MenuModel } from './menu-model.model';
import { UserdataService } from '../../servives/userdata.service';
import { Tables } from '../../common/common-tables';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { LocalStorageService } from '../../servives/local-storage.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css',
})
export class MenuComponent implements OnInit {
  commonTables: Tables[] = [];
  menuModel: any = new MenuModel();
  id = null;
  is_active: any;

  menuName: any;
  isActive: any;
  routeLink: any;

  constructor(
    private userData: UserdataService,
    private route: ActivatedRoute,
    private router: Router,
    private commonMethods: CommonMethodsService,
    private localStorage: LocalStorageService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params[ROUTE_ID];

    if (this.id != null && this.id != undefined) {
      this.getOneMenu();
    }
  }

  //to set record's data in model to show in edit form when you 
    //click on edit(update) button
  getOneMenu() {
    this.userData.getOneMenu(this.id).subscribe((res: any) => {
      this.menuModel = res;

      this.menuName = res.menu_name;
      this.isActive = res.is_active;
      this.routeLink = res.route_link;
    });
  }

  //check form is changed
  formChanged(): boolean {
    if (
      this.menuName !== this.menuModel.menu_name ||
      this.isActive !== this.menuModel.is_active ||
      this.routeLink !== this.menuModel.route_link
    ) {
      return true;
    } else {
      return false;
    }
  }

  //to update record
  updateMenu() {
    //this.localStorage.removeItem(KEY_MENU);
    if(this.formChanged()) {
      this.userData
        .updateMenu(this.menuModel)
        .subscribe((response: any) => {
          this.commonMethods.errorHandling(response);
          this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_MENU]);
        });
    }
  }

  //if checkbox(switch) is checked is_active status is true otherwise false
  onCheckboxChange(event: any) {
    const checkbox = event.currentTarget as HTMLInputElement;
    this.menuModel.is_active = checkbox.checked ? true : false;
  }

  //to add new record
  addMenu() {
    this.localStorage.removeItem(KEY_MENU);
    this.userData.addMenu(this.menuModel).subscribe((response: any) => {
      this.commonMethods.errorHandling(response);
      this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_MENU]);
    });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { UserdataService } from '../../servives/userdata.service';
import { ProfileModel } from './profile-model.model';
import { ImageUploadsComponent } from '../../directive/image-uploads/image-uploads.component';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {

  API_URL = environment.API_URL;
  profileModel = new ProfileModel();
  updateFlag: boolean=false;

  selectedFile: File | null = null;
  imagePreview:any;

  id: any;
  is_active: any;

  imageUrl: any;
  file: any;

  username:any;
  firstname: any;
  lastname: any;
  contact: any;
  checkbox: any;

  //to access properties or methods from other components, assume this is parent 
  //component and make child referance of other component
  @ViewChild(ImageUploadsComponent)
  ImageUploadsComponentRef!: ImageUploadsComponent;
  

  constructor(
    private userData: UserdataService,
  ){}
  ngOnInit(): void {
    this.getProfile();
    this.updateFlag = false;
  }

  //get profile data
  getProfile() {
    this.userData.profile().subscribe((response: any) => {
      
      if (response.status == 200) {   
        this.profileModel = response.user;
        this.imageUrl = response.user.profile_pic;

        this.username  =  response.user.name;
        this.firstname =  response.user.firstname;
        this.lastname  =  response.user.lastname;
        this.contact   =  response.user.contact;
        this.checkbox  =  response.user.is_active;
      }
      else if (response.status == 401) {
        alert(response.message)
      }
      
    });
  }

  //check form is changed
  formChanged(): boolean {
    if(
      this.username   !==   this.profileModel.name          ||
      this.firstname  !==   this.profileModel.firstname     ||
      this.lastname   !==   this.profileModel.lastname      ||
      this.contact    !==   this.profileModel.contact       ||
      this.checkbox   !==   this.profileModel.is_active     ||
      this.selectedFile
    ) {
      return true;
    } else {
      return false;
    }
  }


  //make update flag true for getting update form
  updateFlagTrue(status:boolean) {
    this.updateFlag = status;
  }


  //update profil
  updateProfile() {
    //javascript method "FormData()" used to send form data
    const formData = new FormData();
    this.id = this.profileModel.id;
    this.is_active = this.profileModel.is_active;
    formData.append('id', this.id);
    formData.append('name', this.profileModel.name);
    formData.append('firstname', this.profileModel.firstname);
    formData.append('lastname', this.profileModel.lastname);
    formData.append('contact', this.profileModel.contact);
    formData.append('is_active', this.is_active);
    this.selectedFile = this.ImageUploadsComponentRef.selectedFile;
    if (this.selectedFile) {
      formData.append('profilePic', this.selectedFile, this.selectedFile.name);
    }
    //if user enters anything new then calls api
    if (this.formChanged()) {
      this.userData.updateProfile(formData).subscribe((response: any) => {
        if (response.status == 200) {
          alert(response.message);
          this.getProfile();
          this.updateFlagTrue(false);
        } else if(response.message.profilePic) {
          alert(JSON.stringify(response.message.profilePic));
        } else if(response.status == 204){
          alert(response.message)
        } else if (response.status == 500) { 
          alert(response.message)
        }
      });
    }
  }

  //if checkbox(switch) is checked is_active status is true otherwise false
  onCheckboxChange(event: any) {
    const checkbox = event.currentTarget as HTMLInputElement;
    this.profileModel.is_active = checkbox.checked ? true : false;
    this.checkbox = checkbox.checked ? true : false;
  }
  
}
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home/home.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { ServicesComponent } from './components/services/services.component';
import { PortfolioDetailComponent } from './components/portfolio-detail/portfolio-detail.component';
import { ServicDetailComponent } from './components/servic-detail/servic-detail.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ProjectManagementComponent } from './components/project-management/project-management.component';
import { DevDetailsComponent } from './components/dev-details/dev-details.component';
import { DevelopmentsComponent } from './components/developments/developments.component';
import { PartnersComponent } from './components/partners/partners.component';
import { SignUpComponent } from './users/sign-up/sign-up.component';
import { LoginComponent } from './users/login/login.component';
import { DashboardComponent } from './users/dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { ProfileComponent } from './users/profile/profile.component';
import { TabsComponent } from './users/tabs/tabs.component';
import { MenuComponent } from './addOrUpdate/menu/menu.component';
import { ServiceComponent } from './addOrUpdate/service/service.component';
import { DevsComponent } from './addOrUpdate/devs/devs.component';
import { PortFolioComponent } from './addOrUpdate/port-folio/port-folio.component';
import { PartnerComponent } from './addOrUpdate/partner/partner.component';
import { CourseComponent } from './addOrUpdate/course/course.component';
import { CityComponent } from './addOrUpdate/city/city.component';
import { CompanyInfoComponent } from './addOrUpdate/company-info/company-info.component';
import { SetPasswordComponent } from './users/set-password/set-password.component';
import { ImageUploadsComponent } from './directive/image-uploads/image-uploads.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'portfoliodetail/:id', component: PortfolioDetailComponent },
  { path: 'serviceDetail/:id', component: ServicDetailComponent },
  { path: 'devDetail/:id', component: DevDetailsComponent },
  
  { path: 'productManagement', component: ProjectManagementComponent },
  { path: 'developments', component: DevelopmentsComponent },
  { path: 'partners', component: PartnersComponent },
  //{ path: 'signUp', component: SignUpComponent, canActivate: [AuthGuard], },
  { path: 'login', component: LoginComponent },
  { path: 'setPassword', component: SetPasswordComponent },
  { path: 'imageUpload', component: ImageUploadsComponent },
  { path: 'errorPage', component: ErrorPageComponent },
  
  
  { 
    path: 'dashboard', 
    component: DashboardComponent, 
    children:[
      { path: 'profile', component: ProfileComponent}, 
      { path: 'signUp', component: SignUpComponent},
      { path: 'tabs/:type', component: TabsComponent},
      { path: 'menu/:id', component: MenuComponent },
      { path: 'menu', component: MenuComponent },
      { path: 'services/:id', component: ServiceComponent },
      { path: 'services', component: ServiceComponent },
      { path: 'developments/:id', component: DevsComponent },
      { path: 'developments', component: DevsComponent },
      { path: 'portfolio/:id', component: PortFolioComponent },
      { path: 'portfolio', component: PortFolioComponent },
      { path: 'partners/:id', component: PartnerComponent },
      { path: 'partners', component: PartnerComponent },
      { path: 'courses/:id', component: CourseComponent },
      { path: 'courses', component: CourseComponent },
      { path: 'city/:id', component: CityComponent },
      { path: 'city', component: CityComponent },
      { path: 'companyInfo/:id', component: CompanyInfoComponent },
      { path: 'companyInfo', component: CompanyInfoComponent },
    ], 
    canActivate: [AuthGuard],
  },



  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    FormsModule, NgbModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [RouterModule]
})
export class AppRoutingModule { }

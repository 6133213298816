import { Component, OnInit, ViewChild } from '@angular/core';
import { Tables } from '../../common/common-tables';
import { Devs } from './devs.model';
import { UserdataService } from '../../servives/userdata.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KEY_DEVDETAILS, ROUTE_ID, ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_DEVELOPMENTS } from '../../utils/app-constants';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { ImageUploadsComponent } from '../../directive/image-uploads/image-uploads.component';
import { LocalStorageService } from '../../servives/local-storage.service';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-devs',
  templateUrl: './devs.component.html',
  styleUrl: './devs.component.css',
})
export class DevsComponent implements OnInit {
  commonTables: Tables[] = [];
  developmentModel: any = new Devs();
  imagePreview: any;
  id = null;
  selectedFile: File | null = null;
  is_active: any;

  API_URL = environment.API_URL;
  imageUrl: any;

  devname: any;
  shortinfo: any;
  isactive: any;

  //to access properties or methods from other components, assume this is parent
  //component and make child referance of other component
  @ViewChild(ImageUploadsComponent)
  ImageUploadsComponentRef!: ImageUploadsComponent;

  constructor(
    private userData: UserdataService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorage: LocalStorageService,
    protected commonMethodsService: CommonMethodsService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params[ROUTE_ID];
    if (this.id != null && this.id != undefined) {
      this.getOneData();
    }
  }

  //to set record's data in model to show in edit form when you
  //click on edit(update) button
  getOneData() {
    this.userData.getOneDevelopment(this.id).subscribe((res: any) => {
      this.developmentModel = res;

      this.imageUrl = res.dev_logo;

      this.devname = res.dev_name;
      this.shortinfo = res.short_info;
      this.isactive = res.is_active;
    });
  }

  //check form is changed
  formChanged(): boolean {
    if (
      this.devname !== this.developmentModel.dev_name ||
      this.shortinfo !== this.developmentModel.short_info ||
      this.isactive !== this.developmentModel.is_active ||
      this.selectedFile
    ) {
      return true;
    } else {
      return false;
    }
  }

  //common function for returning form data
  formData(isupdate: boolean) {
    //javascript method "FormData()" used to send form data
    const formData = new FormData();
    //in update method id is required but not in add method
    if (isupdate == true) {
      this.developmentModel.id = this.id;
      formData.append('id', this.developmentModel.id);
    }
    this.is_active = this.developmentModel.is_active;
    formData.append('dev_name', this.developmentModel.dev_name);
    formData.append('short_info', this.developmentModel.short_info);
    formData.append('is_active', this.is_active);
    
    if (this.selectedFile) {
      formData.append('dev_logo', this.selectedFile, this.selectedFile.name);
    }
    return formData;
  }

  //to update record
  updateData() {
    //this.localStorage.removeItem(KEY_DEVDETAILS);
    this.selectedFile = this.ImageUploadsComponentRef.selectedFile;
    if(this.formChanged()) {
      this.userData
        .updateDevelopment(this.formData(true))
        .subscribe((response: any) => {
          this.commonMethodsService.errorHandling(response);
          this.router.navigate([
            ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_DEVELOPMENTS,
          ]);
        });
    }
  }

  //to add new record
  addData() {
    this.localStorage.removeItem(KEY_DEVDETAILS);
    this.selectedFile = this.ImageUploadsComponentRef.selectedFile;
    if(this.selectedFile !== null) {
      this.userData
      .addDevelopment(this.formData(false))
      .subscribe((response: any) => {
        this.commonMethodsService.errorHandling(response);
        this.router.navigate([
          ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_DEVELOPMENTS,
        ]);
      });
    } else {
      alert("Picture is required!");
    }

  }

  //if checkbox(switch) is checked is_active status is true otherwise false
  onCheckboxChange(event: any) {
    const checkbox = event.currentTarget as HTMLInputElement;
    this.developmentModel.is_active = checkbox.checked ? true : false;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Menu } from '../common/menu';
import { BASE_API_URL } from '../utils/app-constants';
import { CompanyInfo } from '../common/company-info';
import { PortfolioList } from '../common/portfolio-list';
import { ServiceList } from '../common/service-list';
import { Courses } from '../common/courses';
import { City } from '../common/city';
import { Devs } from '../common/devs';
import { Partners } from '../common/partners';

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {

  baseUrl = BASE_API_URL;

  constructor(private httpClinet: HttpClient) { }


  //menu
  getMenuListAPI() {
    return this.httpClinet.get<GetMenuResponse>(this.baseUrl + "/menu").pipe(
      map(response =>
        response.response)
    );

  }


  // getComapanyInfoAPI() {
  //   return this.httpClinet.get<GetCompanyInfoResponse>(this.baseUrl + "/companyInfo").pipe(
  //     map(response => response._embedded.companyInfo)
  //   );
  // }

  //company info
  getComapanyInfoAPI() {
    return this.httpClinet.get<GetCompanyInfoResponse>(this.baseUrl + "/companyInfo").pipe(
      map(response => response.response)
    );
  }

  //address (city)
  getAddressAPI() {
    return this.httpClinet.get<GetCityResponse>(this.baseUrl + "/city").pipe(
      map(response => response.response)
    );

  }

  //portfolio
  getPortFolioListAPI() {
    return this.httpClinet.get<GetPortFolioResponse>(this.baseUrl + "/portfolio").pipe(
      map(response => response.response)
    );

  }

  //services
  getServiceListAPI() {
    return this.httpClinet.get<GetServiceResponse>(this.baseUrl + "/services").pipe(
      map(response => response.response)
    );
  }

  //courses
  getCourseDetailListAPI() {
    return this.httpClinet.get<GetCourseResponse>(this.baseUrl + "/courses").pipe(
      map(response => response.response)
    );
  }

  //developments
  getDevListAPI() {
    return this.httpClinet.get<GetDevResponse>(this.baseUrl + "/devs").pipe(
      map(response => response.response)
    );
  }

  //partners list api
  getPtnListAPI() {
    return this.httpClinet.get<GetPtnResponse>(this.baseUrl + "/partners").pipe(
      map(response => response.response),
      //catchError(this.handleError)
    );
  }

  //posts contact form
  addContacts(data: any)
  {
    return this.httpClinet.post(this.baseUrl + "/contact", data)
  }


}

interface GetMenuResponse {
  response: Menu[]
}

interface GetCompanyInfoResponse {
  response: CompanyInfo[]
}
interface GetCityResponse {
  response: City[]
}
// interface GetCityResponse {
//   _embedded: {
//     city: City[]
//   }
// }

interface GetPortFolioResponse {
  response: PortfolioList[]
}
interface GetServiceResponse {
  response: ServiceList[]
}
interface GetCourseResponse {
  response: Courses[]
}
interface GetDevResponse {
  response: Devs[]
}
interface GetPtnResponse {
  response: Partners[]
}

import { Component, OnInit } from '@angular/core';
import { PortfolioList } from '../../common/portfolio-list';
import { HeaderService } from '../../servives/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KEY_PRODUCTLIST } from '../../utils/app-constants';
import { LocalStorageService } from '../../servives/local-storage.service';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-portfolio-detail',
  templateUrl: './portfolio-detail.component.html',
  styleUrl: './portfolio-detail.component.css'
})
export class PortfolioDetailComponent implements OnInit {

  productDetail: any = PortfolioList
  id = 0;
  currentTab = "";
  API_URL = environment.API_URL;

  constructor(private headerService: HeaderService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit() {

    this.id = this.route.snapshot.params['id'];

    this.currentTab = this.router.url
    if (this.currentTab.includes("portfoliodetail")) {
      this.headerService.updateHeaderValue("Portfolio")
    }

    this.headerService.productDetail.subscribe(
      (productInfo: PortfolioList) => {
        this.productDetail = productInfo
      }
    )

    if (this.localStorageService.getObject(KEY_PRODUCTLIST) != null) {
      this.productDetail = this.localStorageService.getProductById(this.id)
    }

  }
  
  //background image style
  getImageStyle() {
    return {
      //this.image is the image URL that has been generated by your changeImage() function
      // backgroundImage: `url(${this.image})`,
      //It's important to redefine the background position because it will not be used if no `backgroundImage` is provided 
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }
  }
}

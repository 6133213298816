<main class="main">

    <!-- Page Title -->
    <div class="page-title" data-aos="fade">
        <div class="container">
            <nav class="breadcrumbs current">
                <ol>
                    <!-- <li><a href="index.html">Home</a></li> -->
                    <li class="current">Portfolio Details</li>
                </ol>
            </nav>


            <!-- Section Title -->
            <div class="container section-title" data-aos="fade-up">
                <h2>Portfolio Details</h2>
            </div><!-- End Section Title -->
            <!-- <h1>Portfolio Details</h1> -->


        </div>
    </div><!-- End Page Title -->

    <!-- Portfolio Details Section -->
    <section id="portfolio-details" class="portfolio-details section">

        <div class="container" data-aos="fade-up" data-aos-delay="100">

            <div class="row gy-4">

                <div class="col-lg-8">
                    <div class="portfolio-details-slider swiper init-swiper">



                        <div class="swiper-wrapper align-items-center">

                            <div class="swiper-slide">
                                <img class="blink" src="{{API_URL}}/{{productDetail.clientLogo}}" alt="">
                            </div>


                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="portfolio-info" data-aos="fade-up" data-aos-delay="200">
                        <h3>Project information</h3>
                        <ul>
                            <li><strong>Category</strong>: {{productDetail.clientCategory}}</li>
                            <li><strong>Client</strong>: {{productDetail.clientName}}</li>
                        </ul>
                    </div>
                    <div class="portfolio-description" data-aos="fade-up" data-aos-delay="300">
                        <h2>{{productDetail.clientName}}</h2>
                        <p>
                            {{productDetail.shortInfo}}
                        </p>
                    </div>
                </div>

            </div>

        </div>

    </section><!-- /Portfolio Details Section -->

</main>
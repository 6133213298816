import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from '../../servives/header.service';
import { ApiCallService } from '../../servives/api-call.service';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_PRODUCTLIST, KEY_SERVICE, KEY_COURSEDETAILS } from '../../utils/app-constants';
import { ServiceList } from '../../common/service-list';
import { Courses } from '../../common/courses';
import { WebData } from '../../utils/web-data';
import { environment } from '../../../environments/environment.development';
import { CourseComponent } from '../../addOrUpdate/course/course.component';
import { CommonMethodsService } from '../../servives/common-methods.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrl: './services.component.css'
})

export class ServicesComponent implements OnInit {

  serviceList: ServiceList[] = [];
  courseDetailList: Courses[] = [];
  API_URL = environment.API_URL;
  show = false

  isUpdatedParent: boolean = false;

  constructor(private routes: Router,
    private apiCallService: ApiCallService,
    private headerService: HeaderService,
    private localStorage: LocalStorageService,
    private router: Router,
    protected commonMethodsService: CommonMethodsService

  ) { }

  ngOnInit() {

    //checks if companyInfo date modified 
    this.isCompanyDateModified();

    var currentTab = this.router.url

    if (currentTab.toLowerCase().includes("services")) {
      this.headerService.updateHeaderValue("Services")
    }

    var serviceListObj = JSON.parse(this.localStorage.getItem(KEY_SERVICE))
    
    if (serviceListObj == null) {
      this.getServiceList()
      // this.serviceList = WebData.service
      // this.localStorage.setObject(KEY_SERVICE, this.serviceList)
    } else {
      this.serviceList = serviceListObj
    }

    var courseListObj = JSON.parse(this.localStorage.getItem(KEY_COURSEDETAILS))
    if (courseListObj == null) {

      //gets course details
      this.getCourseDetailsList()
      // this.courseDetailList = WebData.course
      // this.localStorage.setObject(KEY_COURSEDETAILS, this.courseDetailList)

    } else {
      this.courseDetailList = courseListObj
    }
  }

  //checks if companyInfo date modified if yes then calls List api
  isCompanyDateModified() {
    this.commonMethodsService.isCompanyDateModified$.subscribe((data) => {
        if(data == true) {
          this.getServiceList();
          this.getCourseDetailsList();
          console.log("isCompanyDateChanged: "+data);
        }
    });
  }

  //services
  getServiceList() {
    //this.isRecordUpdated();
    this.apiCallService.getServiceListAPI().subscribe({
      next: (data: ServiceList[]) => {
        this.serviceList = data
        this.localStorage.setObject(KEY_SERVICE, this.serviceList)

      },
      error: (err: any) => {

      },
      complete() {

      },

    })
  }

  //courses
  getCourseDetailsList() {
    this.apiCallService.getCourseDetailListAPI().subscribe({
      next: (data: Courses[]) => {
        this.courseDetailList = data
        this.localStorage.setObject(KEY_COURSEDETAILS, this.courseDetailList)

      },
      error: (err: any) => {

      },
      complete() {

      },

    })
  }

  //navigate to service detail
  detailPage1(service: Courses) {
    this.routes.navigate(["/serviceDetail", service.id])
    this.headerService.callServiceDetail(service)

  }

}

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CLIENT_SIDE_ERROR, KEY_BEARRER_TOKEN, KEY_TOKEN, ROUTE_ERROR_PAGE, ROUTE_HOME, SERVER_DOWN, UNAUTHENTICATED_MSG } from '../utils/app-constants';
import { ErrorSharedServiceService } from './error-shared-service.service';
import { ContextServiceService } from '../servives/context-service.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  private isErrorDisplayed = false;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private errorSharedServiceService: ErrorSharedServiceService,
    private requestContext: ContextServiceService,
  ) {}

  //METHOD for intercepting api requests
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const componentName = this.requestContext.getContext();
    if(request.url) {
      console.log('API Called:', request.url);
      //this.route.navigate([ROUTE_HOME]);
    }
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(JSON.stringify(error.status)+" "+componentName);
          // if(error.status === 0)
          // {
          //   console.log("connection: "+error.status);
          // }
          
          
          //to send error data to error page
          const sendServerErrorCode = { key: error.status };
          const sendErrorCode = { key: error.error.status };
          const sendErrorMessage = { key: error.error.message };
          this.errorSharedServiceService.updateErrorCode(sendErrorCode);
          this.errorSharedServiceService.updateServerErrorCode(sendServerErrorCode);
          this.errorSharedServiceService.updateErrorMessage(sendErrorMessage);

          const errorMessage = this.setError(error);
          
          //IF token is expired then it shows Unauthenticated message.
          if(errorMessage == UNAUTHENTICATED_MSG || error.error.message == 'Unauthenticated.') {
            
            if((this.route.url != '/login') || (error.status != 401) || componentName == "DashboardComponent") {
              alert("Session expired, please login again.");
              this.route.navigate([ROUTE_HOME]);
            } else if((this.route.url != '/login') || (error.status != 401)) {
              alert("Session expired. Access to dashboard resources is restricted.");
            }
            localStorage.removeItem(KEY_TOKEN);
            localStorage.removeItem(KEY_BEARRER_TOKEN);
           
          } else if((error.status === 401 || error.status === 0) && componentName === "SetPasswordComponent") {
            alert("Token expired, please contact admin to resend verification link."); 
            this.route.navigate([ROUTE_HOME]);
          } else if(error.status === 0) {
            this.route.navigate([ROUTE_ERROR_PAGE]);
          } else if(componentName == "LoginComponent" || "SetPasswordComponent") {
            alert(error.error.message);
          } else if (!this.isErrorDisplayed && errorMessage == SERVER_DOWN && error.status != 0) {
            //server down
            alert(errorMessage);
            this.route.navigate([ROUTE_HOME]);            
            this.isErrorDisplayed = true;      
          } else if((errorMessage != SERVER_DOWN) && componentName != "SetPasswordComponent" ) {
            //not server down
            this.route.navigate([ROUTE_ERROR_PAGE]);
          } else {
            //other errors
            this.route.navigate([ROUTE_ERROR_PAGE]);
          }
          
          return throwError(errorMessage);
        })
      );
  }

  //set client or server side error message 
  setError(error: HttpErrorResponse): string {
    let errorMessage = SERVER_DOWN;

    if(error.error instanceof ErrorEvent) {
      //Server side error
      errorMessage = error.error.message;
    } else {
      //Client side error
      if (error.status!=0){
        errorMessage = CLIENT_SIDE_ERROR + error.error.message;
      }
    }
    return errorMessage;
  }

}
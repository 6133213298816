export class Devs {
    constructor(public id: number,
        public devName: string,
        public devLogo: string,
        public shortInfo: string,
        public isActive: boolean,
        public status: any,
        public response: any,
    ) {

    }
}

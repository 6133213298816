<mat-card>
  <mat-card-content>
    <div class="bootstrap-wraper">
      <div class="container">
        <div class="row ml20 mb20">
          <div class="col-md-6 offset-md-3">
            <mat-card class="mt20 mb20" appearance="outlined" color="bule">
              <mat-card-content>
                <div class="container text-center">
                  <img src="assets\logo.jpeg" alt="logo" class="imglogo" />
                </div>

                <h3 class="text-center">
                  {{ id != null && id != undefined ? "Update" : "Add" }}-Service
                </h3>

                <form
                  #form="ngForm"
                  (ngSubmit)="
                    id != null && id != undefined ? updateData() : addData()
                  "
                >
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Service Name</mat-label>
                    <input
                      #name="ngModel"
                      type="text"
                      pattern="^[a-zA-Z0-9-_ ]+$"
                      class="form-control"
                      required
                      minlength="2"
                      name="name"
                      matInput
                      placeholder="Enter hear"
                      maxlength="50"
                      [(ngModel)]="serviceModel.service_name"
                    />
                    <mat-hint>Minimum length 2</mat-hint>
                    <span
                      *ngIf="name.invalid && name.touched && !form.submitted"
                      class="validationAlertColor"
                    >
                      <span *ngIf="name.errors?.['required']"
                        >Name is required.</span
                      >
                      <span *ngIf="name.errors?.['minlength']"
                        >Name must be at least 2 and at most 20 characters
                        long.</span
                      >
                    </span>
                  </mat-form-field>

                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Short Information</mat-label>
                    <textarea
                      #shortInfo="ngModel"
                      type="text"
                      class="form-control mr10"
                      required
                      name="shortInfo"
                      matInput
                      placeholder="Enter hear"
                      minlength="5"
                      [(ngModel)]="serviceModel.short_info"
                    ></textarea>
                    <mat-hint>short info about your service</mat-hint>
                    <span
                      *ngIf="
                        shortInfo.invalid &&
                        shortInfo.touched &&
                        !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="shortInfo.errors?.['required']"
                        >Short Information is required.</span
                      >
                      <span *ngIf="shortInfo.errors?.['minlength']"
                        >Short Information must be at least 5 characters
                        long.</span
                      >
                    </span>
                  </mat-form-field>

                  <div class="full-width mb20" appearance="outline">
                    <span *ngIf="form.invalid" class="validationAlertColor"
                      >Please enter required fields.</span
                    >
                  </div>

                  <div class="container text-center">
                    <button
                      mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="form.invalid"
                    >
                      {{ id != null && id != undefined ? "Update" : "Add" }}
                    </button>

                    <button
                      class="mx-3"
                      mat-raised-button
                      color="primary"
                      routerLink="/dashboard/tabs/services"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContextServiceService {

  constructor() { }

  private context: string | null = null;

  setContext(context: string) {
    this.context = context;
  }

  getContext() {
    return this.context;
  }

  clearContext() {
    this.context = null;
  }
}

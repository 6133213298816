import { Component, OnInit } from '@angular/core';
import { Courses } from '../../common/courses';
import { Router } from '@angular/router';
import { ApiCallService } from '../../servives/api-call.service';
import { HeaderService } from '../../servives/header.service';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_COMPANYINFO, KEY_DEV, KEY_DEVDETAILS } from '../../utils/app-constants';
import { Devs } from '../../common/devs';
import { environment } from '../../../environments/environment.development';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { CompanyInfo } from '../../common/company-info';

@Component({
  selector: 'app-developments',
  templateUrl: './developments.component.html',
  styleUrl: './developments.component.css'
})
export class DevelopmentsComponent implements OnInit {

  devs: Devs[] = [];
  courseDetailList: Courses[] = [];
  API_URL = environment.API_URL;
  show = false

  constructor(private routes: Router,
    private apiCallService: ApiCallService,
    private headerService: HeaderService,
    private localStorage: LocalStorageService,
    private router: Router,
    protected commonMethodsService: CommonMethodsService

  ) { }

  ngOnInit() {

    //checks if companyInfo date modified 
    this.isCompanyDateModified();

    var currentTab = this.router.url
    if (currentTab.toLowerCase().includes("developments")) {
      this.headerService.updateHeaderValue("Developments")
    }

    var devListObj = JSON.parse(this.localStorage.getItem(KEY_DEVDETAILS))
    if (devListObj == null) {
      //list of developments
      this.getDevsList();
    } else {
      this.devs = devListObj
    }
  }

  //checks if companyInfo date modified if yes then calls List api
  isCompanyDateModified() {
    this.commonMethodsService.isCompanyDateModified$.subscribe((data) => {
      if (data == true) {
        this.getDevsList();
        console.log("isCompanyDateChanged: " + data);
      }
    });
  }

  //developments
  getDevsList() {
    this.apiCallService.getDevListAPI().subscribe({
      next: (data: Devs[]) => {
        this.localStorage.removeItem(KEY_DEVDETAILS);
        this.devs = data;
        this.localStorage.setObject(KEY_DEVDETAILS, this.devs)
        console.log("devs api called")
      },

      error: (err: any) => {

      },
      complete() {

      },

    })
  }

  detailPage(devs: Devs) {
    this.routes.navigate(["/devDetail", devs.id])
    this.headerService.callDevDetail(devs)

  }

}
import { Component, OnInit } from '@angular/core';
import { Login } from './login.model';
import { UserdataService } from '../../servives/userdata.service';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { KEY_BEARRER_TOKEN, ROUTE_DASHBOARD } from '../../utils/app-constants';
import { Router } from '@angular/router';
import { ContextServiceService } from '../../servives/context-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {

  login = new Login
  loginUrl: any;
  bearerToken: any;
  url: any;
  resetPasswordFlag: boolean=false;

  constructor(
    private userdata: UserdataService,
    private commonMethods: CommonMethodsService,
    private route: Router,
    private requestContext: ContextServiceService,
  ) {}

  ngOnInit(): void {
    this.requestContext.setContext('LoginComponent');
    const componentName = this.requestContext.getContext();
    this.resetPasswordFlag = false;
    this.bearerToken = localStorage.getItem(KEY_BEARRER_TOKEN);
    this.url = this.route.url === '/login';
    if(this.url == true && this.bearerToken !=null ) {
      this.validateToken();
    }
  }

  //make update flag true for getting update form
  resetPasswordFlagTrue(status:boolean) {
    this.resetPasswordFlag = status;
  }

  //sends password reset link to registered email
  sendPasswordResetLink() {
    this.userdata.sendPasswordResetLink(this.login).subscribe((response: any) => {
      if (response.status == 200) {
        alert(response.message)
      }
      else if (response.status == 401) {
        alert(response.message)      
      }
    });
  }
  

  //sends token to server if valid then sends status 200 back
  validateToken() {
    this.userdata.validateToken().subscribe((response: any) => {
      if (response.status == 200) {
        this.route.navigate([ROUTE_DASHBOARD]);
      }
    });

  }

  //login
  loginUser() {
    this.userdata.loginUser(this.login).subscribe((response: any) => {
      this.commonMethods.errorHandlingLogin(response);
    });
  }


  //hide/show password in login form
  show_password = false;
  showpasswordchars(){
    if(this.show_password == false)
    {
      this.show_password = true;
    }
    else{
      this.show_password = false;

    }
  }

}

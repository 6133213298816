import { Component, ViewChild  } from '@angular/core';
import { ImageUploads } from './image-uploads.model';
import { SetPasswordComponent } from '../../users/set-password/set-password.component';

@Component({
  selector: 'app-image-uploads',
  templateUrl: './image-uploads.component.html',
  styleUrl: './image-uploads.component.css'
})
export class ImageUploadsComponent {
  //to access properties or methods from other components, assume this is parent 
  //component and make child referance of other component
  // @ViewChild(SetPasswordComponent)
  // SetPasswordComponentRef!: SetPasswordComponent;

  selectedFile: File | null = null;
  imagePreview:any;
  imageUploadsModel = new ImageUploads();

  constructor(

  ) {}




  //function to check file is selected or not for form
  onImageUpload(event: any) {
  if (event.target.files.length > 0) {
    this.selectedFile = event.target.files[0];
  }
    if(this.selectedFile)
    {
      const reader = new FileReader;

      reader.onload = (e:any) => {
        this.imagePreview = e.target.result;
      }

      reader.readAsDataURL(this.selectedFile);
    }
  }

    //is file selected for view child
    isFileSelected() {
      if(this.selectedFile === null){
        return false;
      } else {
        return true;
      }
    }

}

export class Course {
    id: number=0;
    course_name: string= "";
    is_active: boolean= false;
    course_logo: any;
    short_info: string= "";
    course_duration: string= "";
    course_time: string= "";
    course_mode: string= "";
    contact: string= "";
}

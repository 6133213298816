import { Component, OnInit } from '@angular/core';
import { CompanyInfo } from '../../common/company-info';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_PRODUCTLIST } from '../../utils/app-constants';
import { PortfolioList } from '../../common/portfolio-list';
import { ApiCallService } from '../../servives/api-call.service';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { environment } from '../../../environments/environment.development';
import { LoaderService } from '../../servives/loader.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent implements OnInit {

  [x: string]: any;

  lat = 51.678418;
  lng = 7.809007;

  portfolioList: PortfolioList[] = [];

  companyInfo: any = CompanyInfo
  imageObject: Array<object> = []

  API_URL = environment.API_URL;
  //default image placeholder
  private defaultImageUrl = 'assets/default_image.png';

  constructor(
    private localStorage: LocalStorageService,
    private apiCallService: ApiCallService,
    protected commonMethodsService: CommonMethodsService,
  ) {

  }
  ngOnInit() {

    var productListObj = JSON.parse(this.localStorage.getItem(KEY_PRODUCTLIST))
    if (productListObj == null) {
      //list of portfolio
      this.getPortFolioList()

      //this.portfolioList = WebData.portfolio
      // this.localStorage.setObject(KEY_PRODUCTLIST, this.portfolioList)
      // this.setHomeClientImages(this.portfolioList)

    } else {
      //saving local data
      this.setHomeClientImages(productListObj)
    }

  }

  //image slider images
  setHomeClientImages(productListObj: PortfolioList[]) {
    //this.loader.hideLoader();
    this.imageObject = []; // Reset the image object

    productListObj.forEach(element => {
      const clientLogo = element.clientLogo ? element.clientLogo : this.defaultImageUrl;
      const imageUrl = this.API_URL + "/" + clientLogo;

      this.commonMethodsService.validateImageUrl(imageUrl).subscribe(isValid => {
        const finalImageUrl = isValid ? imageUrl : this.defaultImageUrl;

        this.imageObject.push({
          image: finalImageUrl,
          thumbImage: finalImageUrl,
          alt: "alt of image",
          title: element.clientName
        });
      });
    });
  }


  //gets portfolio
  getPortFolioList() {
    this.apiCallService.getPortFolioListAPI().subscribe({
      next: (data: PortfolioList[]) => {
        this.portfolioList = data
        this.localStorage.setObject(KEY_PRODUCTLIST, this.portfolioList)
        this.setHomeClientImages(this.portfolioList)


      },
      error: (err: any) => {

      },
      complete() {

      },

    })
  }

}
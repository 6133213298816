import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tables } from '../common/common-tables';
import { map, Observable } from 'rxjs';
import { KEY_BEARRER_TOKEN, ROUTE_SLASH_LOGOUT, ROUTE_SLASH_TABLE_RECORD_SLASH } from '../utils/app-constants';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {

  private API_URL = environment.API_URL;

  constructor(
    private httpRequest: HttpClient,
  ) {}



  //sends token to server if valid then sends status 200 back
  validateToken() {
    return this.httpRequest.get(this.API_URL + '/validate-token' , {
      headers: this.tokenHeader()
    });
  }

  // , { responseType: 'text' } register/add new  user
  registerUser(data: any) { 
    return this.httpRequest.post(this.API_URL + '/register', data, {
      headers: this.tokenHeader()
    });
  }

  loginUser(data: any) {
    return this.httpRequest.post(this.API_URL + '/auth/login', data);
  }

  //gets profile data
  profile() {
    return this.httpRequest.get(this.API_URL + '/profile', {
      headers: this.tokenHeader()
    });
  }

  //updates profile
  updateProfile(data: any) {
    return this.httpRequest.post(this.API_URL + '/update-profile' , data, {
      headers: this.tokenHeader()
    });
  }

  //set Password
  setPassword( data: any) {
    return this.httpRequest.post(this.API_URL + '/set-password' , data, {
    });
  }

  //isTokenExpired
  isTokenExpired( email1: string) {
    const data = { email: email1 }
    return this.httpRequest.post(this.API_URL + '/isTokenExpired' , data, {

    });
  }

  //imageUpload
  imageUpload( data: any) {
    return this.httpRequest.post(this.API_URL + '/imageUpload' , data, {
    });
  }
  //resendVerificationLink
  resendVerificationLink( data: any) {
    return this.httpRequest.post(this.API_URL + '/resendVerificationLink' , data, {
      headers: this.tokenHeader()
    });
  }

  //reset Password
  resetPassword(data: any) {
    return this.httpRequest.post(this.API_URL + '/reset-password', data, {
    });
  }

  //send reset Password Link
  sendPasswordResetLink(data: any) {
      return this.httpRequest.post(this.API_URL + '/sendPasswordResetLink', data); 
  }

//TO set header of bearer token 
  tokenHeader() {
    var head:any="" ;
    head=localStorage.getItem(KEY_BEARRER_TOKEN);
    var header = new HttpHeaders({
      'Authorization': head,
    });
    return header;
  }

  
  
  logoutUser() {
   return this.httpRequest.get(this.API_URL + ROUTE_SLASH_LOGOUT , {
      headers: this.tokenHeader()
    }); 
  }


  getTblrecords(type:String) {
    return this.httpRequest.get<GetTblsResponse>(this.API_URL + ROUTE_SLASH_TABLE_RECORD_SLASH + type, {
      headers: this.tokenHeader()
    }).pipe(
      map(response => response.response),     
    );
  }

  //menu
  addMenu(data: any) {
    return this.httpRequest.post(this.API_URL + '/addMenu', data,{
      headers: this.tokenHeader()
    });
  }
  deleteMenu(id: number) {
    return this.httpRequest.delete(this.API_URL + '/deleteMenu/' + id,{
      headers: this.tokenHeader()
    });
  }
  getOneMenu(id:any) {
    return this.httpRequest.get(this.API_URL + '/getOneMenu/' + id,{
      headers: this.tokenHeader()
    });
  }
  updateMenu( data: any) {
    return this.httpRequest.patch(this.API_URL + '/updateMenu' , data, {
      headers: this.tokenHeader()
    });
  }


  //service
  addService(data: any) {
    return this.httpRequest.post(this.API_URL + '/addService', data, {
      headers: this.tokenHeader()
    });
  }
  deleteService(id: number) {
    return this.httpRequest.delete(this.API_URL + '/deleteService/' + id, {
      headers: this.tokenHeader()
    });
  }
  getOneService(id:any) {
    return this.httpRequest.get(this.API_URL + '/getOneService/' + id, {
      headers: this.tokenHeader()
    });
  }
  updateService( data: any) {
    return this.httpRequest.patch(this.API_URL + '/updateService' , data, {
      headers: this.tokenHeader()
    });
  }

  //Development
  addDevelopment(data: any) {
    return this.httpRequest.post(this.API_URL + '/addDevelopment', data, {
      headers: this.tokenHeader()
    });
  }
  deleteDevelopment(id: number) {
    return this.httpRequest.delete(this.API_URL + '/deleteDevelopment/' + id, {
      headers: this.tokenHeader()
    });
  }
  getOneDevelopment(id:any) {
    return this.httpRequest.get(this.API_URL + '/getOneDevelopment/' + id, {
      headers: this.tokenHeader()
    });
  }
  updateDevelopment( data: any) {
    return this.httpRequest.post(this.API_URL + '/updateDevelopment' , data, {
      headers: this.tokenHeader()
    });
  }

  //Portfolio
  addPortfolio(data: any) {
    return this.httpRequest.post(this.API_URL + '/addPortfolio', data, {
      headers: this.tokenHeader()
    });
  }
  deletePortfolio(id: number) {
    return this.httpRequest.delete(this.API_URL + '/deletePortfolio/' + id, {
      headers: this.tokenHeader()
    });
  }
  getOnePortfolio(id:any) {
    return this.httpRequest.get(this.API_URL + '/getOnePortfolio/' + id, {
      headers: this.tokenHeader()
    });
  }
  updatePortfolio( data: any) {
    return this.httpRequest.post(this.API_URL + '/updatePortfolio' , data, {
      headers: this.tokenHeader()
    });
  }

  //Partners
  addPartners(data: any) {
    return this.httpRequest.post(this.API_URL + '/addPartners', data, {
      headers: this.tokenHeader()
    });
  }
  deletePartners(id: number) {
    return this.httpRequest.delete(this.API_URL + '/deletePartners/' + id, {
      headers: this.tokenHeader()
    });
  }
  getOnePartners(id:any) {
    return this.httpRequest.get(this.API_URL + '/getOnePartners/' + id, {
      headers: this.tokenHeader()
    });
  }
  updatePartners( data: any) {
    return this.httpRequest.post(this.API_URL + '/updatePartners' , data, {
      headers: this.tokenHeader()
    });
  }

  //Courses
  addCourse(data: any) {
    return this.httpRequest.post(this.API_URL + '/addCourse', data, {
      headers: this.tokenHeader()
    });
  }
  deleteCourse(id: number) {
    return this.httpRequest.delete(this.API_URL + '/deleteCourse/' + id, {
      headers: this.tokenHeader()
    });
  }
  getOneCourse(id:any) {
    return this.httpRequest.get(this.API_URL + '/getOneCourse/' + id, {
      headers: this.tokenHeader()
    });
  }
  updateCourse( data: any) {
    return this.httpRequest.post(this.API_URL + '/updateCourse' , data, {
      headers: this.tokenHeader()
    });
  }

  //City
  addCity(data: any) {
    return this.httpRequest.post(this.API_URL + '/addCity', data, {
      headers: this.tokenHeader()
    });
  }
  deleteCity(id: number) {
    return this.httpRequest.delete(this.API_URL + '/deleteCity/' + id, {
      headers: this.tokenHeader()
    });
  }
  getOneCity(id:any) {
    return this.httpRequest.get(this.API_URL + '/getOneCity/' + id, {
      headers: this.tokenHeader()
    });
  }
  updateCity( data: any) {
    return this.httpRequest.patch(this.API_URL + '/updateCity' , data, {
      headers: this.tokenHeader()
    });
  }

  //CompanyInfo
  addCompanyInfo(data: any) {
    return this.httpRequest.post(this.API_URL + '/addCompanyInfo', data, {
      headers: this.tokenHeader()
    });
  }
  deleteCompanyInfo(id: number) {
    return this.httpRequest.delete(this.API_URL + '/deleteCompanyInfo/' + id, {
      headers: this.tokenHeader()
    });
  }
  getOneCompanyInfo(id:any) {
    return this.httpRequest.get(this.API_URL + '/getOneCompanyInfo/' + id, {
      headers: this.tokenHeader()
    });
  }
  
  updateCompanyInfo(data: any) {
    return this.httpRequest.post(this.API_URL + '/updateCompanyInfo' , data, {
      headers: this.tokenHeader()
    });
  }

  //updates date modified in company info to current timestamp
  refreshDateModified(companyInfoId: any) {
    return this.httpRequest.patch(this.API_URL + '/refreshDateModified', companyInfoId,{
      params: { companyInfoId: companyInfoId.toString() },
      headers: this.tokenHeader(),
    });
  }

}



interface GetTblsResponse {
  response: Tables[]
}
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../servives/local-storage.service';
import { UserdataService } from '../../servives/userdata.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Tables } from '../../common/common-tables';
import { Service } from './service.model';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { KEY_SERVICE, ROUTE_ID, ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_SERVICES } from '../../utils/app-constants';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrl: './service.component.css'
})
export class ServiceComponent implements OnInit {
  commonTables: Tables[] = [];
  serviceModel: any = new Service();
  id = null;

  serviceName: any;
  shortInfo: any;

  constructor(
    private userData: UserdataService,
    private route: ActivatedRoute,
    private router: Router,
    private commonMethods: CommonMethodsService,
    private localStorage: LocalStorageService,
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params[ROUTE_ID];

    if (this.id != null && this.id != undefined) {
      this.getOneData();
    }
  }

  //to set record's data in model to show in edit form when you click 
  //on edit(update) button
  getOneData() {
    this.userData.getOneService(this.id).subscribe((res: any) => {
      this.serviceModel = res;

      this.serviceName = res.service_name;
      this.shortInfo = res.short_info;
    });
  }

  //check form is changed
  formChanged(): boolean {
    if (
      this.serviceName !== this.serviceModel.service_name ||
      this.shortInfo !== this.serviceModel.short_info 
    ) {
      return true;
    } else {
      return false;
    }
  }

  //to update record
  updateData() {
    //this.localStorage.removeItem(KEY_SERVICE);
    if(this.formChanged()) {
      this.userData
        .updateService( this.serviceModel)
        .subscribe((response: any) => {
          this.commonMethods.errorHandling(response);
          this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_SERVICES]);
        });
    }
  }


  //to add new record
  addData() {
    this.localStorage.removeItem(KEY_SERVICE);
    this.userData.addService(this.serviceModel).subscribe((response: any) => {
      this.commonMethods.errorHandling(response);
      this.router.navigate([ROUTE_SLASH_DASHBOARD_SLASH_TABS_SLASH_SERVICES]);
    });
  }

}

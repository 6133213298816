export class ProfileModel {
    public id: number=0;
    public name: string='';       
    public firstname: string='';       
    public lastname: string='';       
    public contact: string='';       
    public is_active: boolean=false;       
    public email: string='';
    public profilePic: any;
}

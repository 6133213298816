import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../servives/header.service';
import { ApiCallService } from '../../servives/api-call.service';
import { Menu } from '../../common/menu';
import { LocalStorageService } from '../../servives/local-storage.service';
import { KEY_COMPANYINFO, KEY_MENU, WEB_KEY } from '../../utils/app-constants';
import { CompanyInfo } from '../../common/company-info';
import { City } from '../../common/city';
import { NavigationEnd, Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { UserdataService } from '../../servives/userdata.service';
import { environment } from '../../../environments/environment';
import { CommonMethodsService } from '../../servives/common-methods.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})

export class HeaderComponent implements OnInit {

  collapsed = true;
  API_URL = environment.API_URL;

  activeTab: string = 'Home';
  menu: Menu[] = [];
  companyInfo: any = CompanyInfo;
  city: City[] = [];

  target: string = '';
  token = '';
  value: string = '';
  isAuthTokenValid: boolean = false;

  padmtech: string = "Padmtech Software Solutions";

  targetVal: any;
  tokenVal = localStorage.getItem('token');
  header = new HttpHeaders({
    'Authorization': `Bearer ${this.tokenVal}`,
  });



  constructor(private headerService: HeaderService,
    private apiCallService: ApiCallService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private userdata: UserdataService,
    protected commonMethodsService: CommonMethodsService,
  ) {

  }

  activeTab$ = this.headerService.selectedHeader$;

  isCompanyInfoTableEmpty: boolean = false;


  ngOnInit() {

    var localToken: string = localStorage.getItem('token') + "";
    if (localToken == null) {
      this.isAuthTokenValid = true;
    } else {
      this.isAuthTokenValid = false;
    }

    this.headerService.selectedHeader$.subscribe((value) => {
      this.activeTab = value;
    });
    this.activeTab = "Home"

    // JSON.parse(localStorage.getItem(KEY_MENU)!);
    var menuList = JSON.parse(this.localStorageService.getItem(KEY_MENU))

    if (menuList != null) {

      this.menu = menuList;

    } else {
      this.getMenuApi();
    }

    var localCompanyInfo = JSON.parse(this.localStorageService.getItem(KEY_COMPANYINFO));
    if (localCompanyInfo == null) {
      this.getComapnyInfoApi();
    }
    //getting company info using getComapnyInfoApi()
    this.getComapnyInfoApi();

    //if current url == '/home' then calls api
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event.url == '/home') {
        this.getComapnyInfoApi();
      }
    });

  }


  //checks current url starts with '/dashboard'
  isDashboard(): boolean {
    return this.router.url.startsWith('/dashboard');
  }


  //header menus
  getMenuApi() {
    this.apiCallService.getMenuListAPI().subscribe(data => {
      this.menu = data;
      this.localStorageService.setObject(KEY_MENU, this.menu);
    })
  }




  //gets company info plus city
  getComapnyInfoApi() {
    //getting address from api
    this.apiCallService.getAddressAPI().subscribe({
      next: (data: City[]) => {
        this.city = data;
      },
      error: (err: any) => {

      },
      complete() {

      },

    })
    //getting company info from api
    this.apiCallService.getComapanyInfoAPI().subscribe({
      next: (data: CompanyInfo[]) => {

        this.commonMethodsService.updateCompanyInfoId(data[0].id);

        // Object.keys(data).length === 0
        if (!data || Object.keys(data).length === 0) {
          this.isCompanyInfoTableEmpty = true;
          this.headerService.updateIsCompanyInfoTableEmpty(true);
          this.commonMethodsService.updateCompanyDateModified(null);
        } else {
          this.isCompanyInfoTableEmpty = false;
          this.headerService.updateIsCompanyInfoTableEmpty(false);
          this.commonMethodsService.updateCompanyDateModified(data[0].dateModified);
        }

        //getting company info from local storage in a variable
        var localStorageCompanyInfo = JSON.parse(this.localStorageService.getItem(KEY_COMPANYINFO));

        // demo dates:
        // var apiResponseDate   = new Date();
        // var localStorageDate = new Date();
        // localStorageDate.setDate(localStorageDate.getDate() - 2);

        if (localStorageCompanyInfo == null) {
          //saving api data in local storage if local storage is null
          this.companyInfo = data[0];
          this.companyInfo.city = this.city;
          this.localStorageService.setObject(KEY_COMPANYINFO, this.companyInfo);
          this.localStorageService.setItem(WEB_KEY, this.companyInfo.companyName);
        } else {
          //else getting local storage date and api response date                   
          var localStorageDate: Date = localStorageCompanyInfo?.dateModified;
          var apiResponseDate: Date = data[0].dateModified;
          //comparing that if api response date is greater than local storage date 
          //if so then clear local storage and update it
          if (localStorageDate < apiResponseDate) {
            //checks if companyInfo date modified
            this.commonMethodsService.updateIsLocalDateModified(true);
            console.log('date is greater than previous date')
            //clear local storage
            this.localStorageService.clearSession();
            this.companyInfo = data[0];
            this.companyInfo.city = this.city;
            this.getMenuApi();

            //update local storage with api response data
            this.localStorageService.setObject(KEY_COMPANYINFO, this.companyInfo);
            this.localStorageService.setItem(WEB_KEY, this.companyInfo.companyName);

          } else {
            this.commonMethodsService.updateIsLocalDateModified(false);
            this.companyInfo = localStorageCompanyInfo;
          }

          //update company info
          this.headerService.updateComapnyInfoValue()
        }


      },
      error: (err: any) => {

      },
      complete() {

      },

    })

  }

  //active tab
  actionHeaderTab(activeTab: string, $event: MouseEvent): void {
    $event.preventDefault();
    this.activeTab = activeTab;
  }

  //logout
  logoutUser() {
    var c = confirm("Are you sure you want to logout?");
    if (c) {

      console.log('Headers:', this.header.keys().map(key => `${key}: ${this.header.get(key)}`).join(', '));
      this.userdata.logoutUser().subscribe((response: any) => {


        if (response.code == 1) {
          localStorage.removeItem('token');
          localStorage.removeItem('BearerToken');
          this.router.navigate(['home']);
        }
        else if (response.code == 2) {
          this.target = '<div class="alert alert-danger" > Error! ' + response.message + '</div>';
        }

      });
    }

  }

  defaultImageUrl = 'assets/logo.jpeg';

  //if image not loads it sets default image
  onImageError(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.defaultImageUrl;
    imgElement.onerror = null; // Prevent infinite loop in case default image is broken
  }

}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Menu } from '../../common/menu';
import { Tables } from '../../common/common-tables';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '../../servives/header.service';
import { UserdataService } from '../../servives/userdata.service';
import { DELETE_CONFIRMATION, KEY_COMPANYINFO, KEY_IS_SHOW_ADD_BUTTON } from '../../utils/app-constants';
import { Subscription } from 'rxjs';
import { CommonMethodsService } from '../../servives/common-methods.service';
import { LocalStorageService } from '../../servives/local-storage.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.css'
})
export class TabsComponent implements OnInit, OnDestroy{
  menu: Menu[] = [];
  tables: Tables[] = [];
  type: string = '';
  target: string = '';
  clickEventsubscription!: Subscription;
  isConstructorLoading: boolean = false;

  isCompanyInfoTableEmpty: boolean = false;
  isShowAddButton: boolean = true;

  tabsCompanyDateModified: any = null;

  private subscription: Subscription = new Subscription;

  constructor(
    private headerService: HeaderService,
    private userData: UserdataService,
    private route: ActivatedRoute,
    private commonMethods: CommonMethodsService,  
    private localStorageService: LocalStorageService,
  ) {
    console.log('tab component is contructor loading: '+this.isConstructorLoading)
      
  }

  ngOnInit() { 
    this.type = this.route.snapshot.params['type'];
    if(this.isConstructorLoading == false){
      this.getTbl(this.type);
    } 

    this.subscription = this.headerService.tableDetail().subscribe((data: any) => {
      if(data!=null && data!="" && data!=undefined) {
        this.type = data;
        this.getTbl(this.type);
        this.isConstructorLoading == true
      }     
    })


    this.headerService.isCompanyInfoTableEmpty$.subscribe((data: boolean) => {
        if(data != null) {
          this.isCompanyInfoTableEmpty = data;
        }
    });
        
  }

  ngOnDestroy() {
    // this.dataService.dataSubject.unsubscribe()
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  //to get one table type wise like menu, services
  getTbl(type: string){
    this.userData.getTblrecords(type).subscribe({next: (res: Tables[]) => {
      this.tables = [];
      this.tables = res;
      this.tabsCompanyDateModified = res[0].dateModified;
      

      //add new button
      if(type == "companyInfo") {      
        if(Object.keys(res).length === 0) {
          this.isShowAddButton = true;
          this.commonMethods.updateCompanyDateModified(null);
        } else {
          this.isShowAddButton = false;
        }
        this.commonMethods.updateCompanyDateModified(res[0].dateModified);
      }
    }});
  }



  //delete table record
  deleteData(id: number) {
    var confirmationPopup = confirm(DELETE_CONFIRMATION);
    if (confirmationPopup) {
      if(this.type == 'menu') {
        this.userData.deleteMenu(id).subscribe((response: any) => {
          this.commonMethods.errorHandlingDelete(response);
          this.getTbl(this.type);  // refresh the table data
        });
      } else if(this.type == 'portfolio') {
        this.userData.deletePortfolio(id).subscribe((response: any) => {
          this.commonMethods.errorHandlingDelete(response);
          this.getTbl(this.type);
        });
      } else if(this.type == 'services') {
        this.userData.deleteService(id).subscribe((response: any) => {
          this.commonMethods.errorHandlingDelete(response);
          this.getTbl(this.type);
        });
      } else if(this.type == 'partners') {
        this.userData.deletePartners(id).subscribe((response: any) => {
          this.commonMethods.errorHandlingDelete(response);
          this.getTbl(this.type);
        });
      } else if(this.type == 'courses') {
        this.userData.deleteCourse(id).subscribe((response: any) => {
          this.commonMethods.errorHandlingDelete(response);
          this.getTbl(this.type);
        });
      } else if(this.type == 'developments') {
        this.userData.deleteDevelopment(id).subscribe((response: any) => {
          this.commonMethods.errorHandlingDelete(response);
          this.getTbl(this.type);
        });
      } else if(this.type == 'city') {
        this.userData.deleteCity(id).subscribe((response: any) => {
          this.commonMethods.errorHandlingDelete(response);
          this.getTbl(this.type);
        });
      } else if(this.type == 'companyInfo') {
        this.userData.deleteCompanyInfo(id).subscribe((response: any) => {
          this.commonMethods.errorHandlingDelete(response);
          if(response.status == 200) {
            this.localStorageService.setObject(KEY_IS_SHOW_ADD_BUTTON, true);
            this.isShowAddButton = this.localStorageService.getItem(KEY_IS_SHOW_ADD_BUTTON);
            this.localStorageService.removeItem(KEY_COMPANYINFO);
            this.headerService.updateIsCompanyInfoTableEmpty(true);
          } else {
            this.localStorageService.setObject(KEY_IS_SHOW_ADD_BUTTON, false);
            this.isShowAddButton = this.localStorageService.getItem(KEY_IS_SHOW_ADD_BUTTON);
            this.headerService.updateIsCompanyInfoTableEmpty(false);
          }
          this.getTbl(this.type);
        });
      }
    }
  }

  
}

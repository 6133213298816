@if(resetPasswordFlag==false){<div class="bootstrap-wraper">
  <div class="container">
    <div class="row ml20 mb20">
      <div class="col-md-6 offset-md-3">
        <mat-card class="mt20 mb20" appearance="outlined" color="bule">
          <mat-card-content>
            <div class="container text-center">
              <img src="assets\logo.jpeg" alt="logo" class="loginImgLogo" />
            </div>

            <h3 class="text-center">Login hear!!</h3>

            <form #loginForm="ngForm" (ngSubmit)="loginUser()">
              <!-- email -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Email</mat-label>
                <input
                  #userEmail="ngModel"
                  type="email"
                  class="form-control pass"
                  required
                  name="email"
                  matInput
                  placeholder="Enter hear"
                  [(ngModel)]="login.email"
                  aria-describedby="emailHelp"
                />
                <span *ngIf="userEmail.invalid && userEmail.touched && !loginForm.submitted" class="validationAlertColor">
                  <span *ngIf="userEmail.errors?.['required']">Email is required.</span>
                  <span *ngIf="userEmail.errors?.['email']">Please enter a valid email.</span>
                </span>
              </mat-form-field>

              <!-- password -->
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Password</mat-label>
                <input
                  #userPassword="ngModel"
                  [type]="show_password ? 'text' : 'password'"
                  class="form-control pass"
                  required
                  name="password"
                  matInput
                  placeholder="Enter hear"
                  [(ngModel)]="login.password"
                />
                <i
                  class="fa pl-1"
                  [ngClass]="{
                    'fa-eye': !show_password,
                    'fa-eye-slash': show_password
                  }"
                  id="eye-icon"
                  (click)="showpasswordchars()"
                ></i>
                <span
                  *ngIf="
                    userPassword.invalid &&
                    userPassword.touched &&
                    !loginForm.submitted
                  "
                  class="validationAlertColor"
                  >Please enter a valid password</span
                >
              </mat-form-field>

              <div class="container text-center">
                <button
                  class="float-left"
                  mat-raised-button
                  color="primary"
                  type="submit"
                  [disabled]="loginForm.invalid"
                >
                  Login
                </button>
                <a mat-raised-button class="float-right pointer" (click)="resetPasswordFlagTrue(true)">Reset Password</a>
                <!-- <button class="ml10" mat-raised-button color="primary" type="submit">Reset</button> -->
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>}
@if(resetPasswordFlag == true)
{
  <div class="bootstrap-wraper">
    <div class="container">
      <div class="row ml20 mb20">
        <div class="col-md-6 offset-md-3">
          <mat-card class="mt20 mb20" appearance="outlined" color="bule">
            <mat-card-content>
              <div class="container text-center">
                <img src="assets\logo.jpeg" alt="logo" class="imglogo" />
              </div>
  
              <h3 class="text-center">Send email for reset password</h3>
  
              <form #resetPasswordForm="ngForm" (ngSubmit)="sendPasswordResetLink()">
  
                <!-- email -->
                <mat-form-field class="full-width mb10" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input
                    name="email"
                    matInput
                    placeholder="Enter hear"
                    type="email"
                    #userEmail="ngModel"
                    class="form-control"
                    name="email"
                    required
                    email
                    [(ngModel)]="login.email"
                  />
                  <span *ngIf="userEmail.invalid && userEmail.touched && !resetPasswordForm.submitted" class="validationAlertColor">
                    <span *ngIf="userEmail.errors?.['required']">Email is required.</span>
                    <span *ngIf="userEmail.errors?.['email']">Please enter a valid email.</span>
                  </span>
                </mat-form-field>
                
                
                <div class="full-width mb20" appearance="outline">
                  <span *ngIf="resetPasswordForm.invalid" class="validationAlertColor">Please enter required fields.</span>
                </div>
  
                <div class="container text-center">
                  <button
                    class="float-left"
                    mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="resetPasswordForm.invalid"
                  >
                    Send Email
                  </button>
                  <a mat-raised-button class="float-right pointer" (click)="resetPasswordFlagTrue(false)">Cancle</a>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  
}

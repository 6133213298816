import { Component, OnInit, ViewChild } from '@angular/core';
import { SetPasswordModel } from './set-password-model.model';
import { UserdataService } from '../../servives/userdata.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_HOME, ROUTE_LOGIN} from '../../utils/app-constants';
import { ImageUploadsComponent } from '../../directive/image-uploads/image-uploads.component';
import { ContextServiceService } from '../../servives/context-service.service';


@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.css'
})
export class SetPasswordComponent implements OnInit {

  //to access properties or methods from other components, assume this is parent 
  //component and make child referance of other component
  @ViewChild(ImageUploadsComponent)
  ImageUploadsComponentRef!: ImageUploadsComponent;

  setPasswordModel = new SetPasswordModel();
  confirmationPopup: any;
  selectedFile: File | null = null;
  imagePreview:any;

  emailFromUrl: string="";

  constructor(
    private userData : UserdataService,
    private router: Router, 
    private requestContext: ContextServiceService,
    private route: ActivatedRoute 
  ) {}
  
  ngOnInit() {
    //set contex to get component name when getting error
    this.requestContext.setContext('SetPasswordComponent');
    this.confirmationPopup = confirm('Are you new user?');

    //get email from url
    this.getEmailFromUrl();

    if(this.emailFromUrl) {
      this.sendEmailToBackend(this.emailFromUrl);
    }
  }

  //get email from url
  getEmailFromUrl() {
    this.route.queryParamMap.subscribe((params: any) => {
      this.emailFromUrl = params.get('email');
      console.log('Retrieved Email:', this.emailFromUrl);
    });
  }


  sendEmailToBackend(email: string): void {
    this.userData.isTokenExpired(email).subscribe({
      next: (response) => {
        console.log('Backend Response:', response);
      },
      error: (err) => {
        console.error("err: "+err);
      }
    });
  }


  //set password
  setPassword()
  {
    console.log("Set password call");
    //javascript method "FormData()" used to send form data
    const formData = new FormData();

    formData.append('email', this.setPasswordModel.email);
    formData.append('password', this.setPasswordModel.password);
    // Access child component's properties or methods
    this.selectedFile = this.ImageUploadsComponentRef.selectedFile;
    
    if (this.selectedFile) {
      formData.append('profilePic', this.selectedFile, this.selectedFile.name);
    }

    this.userData.setPassword(formData).subscribe((response: any) => {

      if (response.status == 200) {
        alert(JSON.stringify(response.message));
        this.router.navigate([ROUTE_LOGIN]);
        
      } else if(response.message.profilePic) {
        alert(JSON.stringify(response.message.profilePic));
      } else if (response.status == 400) { 
        alert(JSON.stringify(response.message));
      } else if (response.error == 401) {
        alert("Token expired, please contact admin to resend verification link.");
      }
    });
  }

  //reset password
  resetPassword() {
    console.log("reset password call");
    this.userData.resetPassword(this.setPasswordModel).subscribe((response: any) => {
      if (response.status == 200) {
        alert(response.message)
        this.router.navigate([ROUTE_LOGIN]);
      } else if (response.status == 401 || 404) { 
        alert(response.message)
      }
    });
  }

  //hide/show pass in form
  show_password = false;
  showpasswordchars(){
    if(this.show_password == false)
    {
      this.show_password = true;
    }
    else{
      this.show_password = false;

    }
  }
}

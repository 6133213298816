<mat-card>
  <mat-card-content>
      <div class="bootstrap-wraper">
        <div class="container">
          <div [innerHtml]="target"></div>
          <div class="row ml20 mb20">
            <div class="col-md-6 offset-md-3">
              <mat-card class="mt20 mb20" appearance="outlined" color="bule">
                <mat-card-content>
                  <div class="container text-center">
                    <img src="assets\logo.jpeg" alt="logo" class="imglogo" />
                  </div>
      
                  <h3 class="text-center">{{cid!=null && cid!=undefined?"Update":"Add"}}-City</h3>
      
                  <form #form="ngForm" (ngSubmit)="cid!=null && cid!=undefined?updateData():addData();">
                    <!-- City Name -->
                    <mat-form-field class="full-width mb20" appearance="outline">
                      <mat-label>City Name</mat-label>
                      <input
                        #name="ngModel"
                        type="text"
                        pattern="^[a-zA-Z0-9-_ ]+$"
                        class="form-control"
                        required
                        minlength="2"
                        maxlength="40"
                        name="name"
                        matInput
                        placeholder="Enter hear"
                        [(ngModel)]="cityModel.city_name"
                      />
                      <mat-hint>Minimum length 2</mat-hint>
                      <span
                        *ngIf="
                          name.invalid &&
                          name.touched &&
                          !form.submitted
                        "
                        class="validationAlertColor"
                        >
                        <span *ngIf="name.errors?.['required']">Name is required.</span>
                        <span *ngIf="name.errors?.['minlength']">Name must be at least 2 and at most 20 characters long.</span>
                        </span
                      >
                    </mat-form-field>
      
                    <!-- cityAddress -->
                    <mat-form-field class="full-width mb20" appearance="outline">
                      <mat-label>City Address</mat-label>
                      <textarea
                        #cityAddress="ngModel"
                        type="text"
                        class="form-control mr10"
                        required
                        minlength="5"
                        name="cityAddress"
                        matInput
                        placeholder="Enter hear"
                        [(ngModel)]="cityModel.city_address"
                      ></textarea>
                      <mat-hint>street or colony</mat-hint>
                      <span
                        *ngIf="
                          cityAddress.invalid &&
                          cityAddress.touched &&
                          !form.submitted
                        "
                        class="validationAlertColor"
                        >
                            <span *ngIf="cityAddress.errors?.['required']">City address is required.</span>
                            <span *ngIf="cityAddress.errors?.['minlength']">City address must be at least 5 characters long.</span>
                        </span
                      >
                    </mat-form-field>

                    <!-- pincode -->
                    <mat-form-field class="full-width mb20" appearance="outline">
                      <mat-label>Pincode</mat-label>
                      <input
                        #pincode="ngModel"
                        type="text"
                        class="form-control mr10"
                        required
                        minlength="6"
                        maxlength="20"
                        name="pincode"
                        matInput
                        placeholder="Enter hear"
                        [(ngModel)]="cityModel.pin_code"
                      />
                      <mat-hint>pincode like 416416</mat-hint>
                      <span
                        *ngIf="
                          pincode.invalid &&
                          pincode.touched &&
                          !form.submitted
                        "
                        class="validationAlertColor"
                        >
                            <span *ngIf="pincode.errors?.['required']">pincode time is required.</span>
                            <span *ngIf="pincode.errors?.['minlength']">pincode must be at least 6 and at most 20 characters long.</span>
                        </span
                      >
                    </mat-form-field>

                    <!-- Country -->
                    <mat-form-field class="full-width mb20" appearance="outline">
                      <mat-label>Country</mat-label>
                      <input
                        #country="ngModel"
                        type="text"
                        class="form-control mr10"
                        required
                        minlength="2"
                        maxlength="20"
                        name="country"
                        matInput
                        placeholder="Enter hear"
                        [(ngModel)]="cityModel.country"
                      />
                      <mat-hint>State and country</mat-hint>
                      <span
                        *ngIf="
                          country.invalid &&
                          country.touched &&
                          !form.submitted
                        "
                        class="validationAlertColor"
                        >
                            <span *ngIf="country.errors?.['required']">Country is required.</span>
                            <span *ngIf="country.errors?.['minlength']">Country must be at least 2 and at most 20 characters long.</span>
                        </span
                      >
                    </mat-form-field>

                    <!-- Contact -->
                    <mat-form-field class="full-width mb20" appearance="outline">
                      <mat-label class="pl-5">Contact</mat-label>
                      <div class="inlineElements">
                        <span class="country-code">+91</span>
                        <input
                          #contact="ngModel"
                          type="text"
                          class="form-control mr10"
                          required
                          pattern = "\d{10}$"
                          name="contact"
                          matInput
                          placeholder="Enter hear"
                          [(ngModel)]="cityModel.mobile"
                        />
                      </div>
                      <mat-hint>Write contact like '6375072382'</mat-hint>
                      <span
                        *ngIf="
                          contact.invalid &&
                          contact.touched &&
                          !form.submitted
                        "
                        class="validationAlertColor"
                        >
                            <span *ngIf="contact.errors?.['required']">Contact is required.</span>
                            <span *ngIf="contact.errors?.['pattern']">Please enter 10 digit number.</span>
                        </span
                      >
                    </mat-form-field>

                    <!-- google_map_link -->
                    <mat-form-field class="full-width mb20" appearance="outline">
                      <mat-label>Google Map Link</mat-label>
                      <input
                        #map="ngModel"
                        type="text"
                        class="form-control mr10"
                        required
                        minlength="5"
                        name="map"
                        matInput
                        placeholder="Enter hear"
                        [(ngModel)]="cityModel.google_map_link"
                      />
                      <mat-hint>Google Map Link</mat-hint>
                      <span
                        *ngIf="
                          map.invalid &&
                          map.touched &&
                          !form.submitted
                        "
                        class="validationAlertColor"
                        >
                        <span *ngIf="map.errors?.['required']">Google Map Link is required.</span>
                        <span *ngIf="map.errors?.['minlength']">Google Map Link must be at least 5 characters long.</span>
                        </span
                      >
                    </mat-form-field>

                    <!-- latitude -->
                    <mat-form-field class="full-width mb20" appearance="outline">
                        <mat-label>Latitude</mat-label>
                        <input #latitude="ngModel" type="text" class="form-control mr10"
                            required name="latitude" matInput placeholder="Enter hear" minlength="1"
                            [(ngModel)]="cityModel.latitude" />
                        <mat-hint>latitude in numbers</mat-hint>
                        <span *ngIf="
                            latitude.invalid &&
                            latitude.touched &&
                            !form.submitted
                            " class="validationAlertColor"
                        >
                            <span *ngIf="longitude.errors?.['required']">Latitude is required.</span>
                            <span *ngIf="longitude.errors?.['minlength']">Latitude must be at least 1 characters long.</span>              
                        </span>
                    </mat-form-field>

                    <!-- Longitude -->
                    <mat-form-field class="full-width mb20" appearance="outline">
                        <mat-label>Longitude</mat-label>
                        <input #longitude="ngModel" type="text" class="form-control mr10"
                            required name="longitude" matInput placeholder="Enter hear" minlength="1"
                            [(ngModel)]="cityModel.longitude" />
                        <mat-hint>longitude in numbers</mat-hint>
                        <span *ngIf="
                            longitude.invalid &&
                            longitude.touched &&
                            !form.submitted
                            " class="validationAlertColor"
                        >
                            <span *ngIf="longitude.errors?.['required']">Longitude is required.</span>
                            <span *ngIf="longitude.errors?.['minlength']">Longitude must be at least 1 characters long.</span>              
                        </span>
                    </mat-form-field>

                    <div class="full-width mb20" appearance="outline">
                        <span *ngIf="form.invalid" class="validationAlertColor">Please enter required fields.</span>
                    </div>
      
                    <div class="container text-center">
                      <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        [disabled]="form.invalid"
                        
                      >
                      {{cid!=null && cid!=undefined?"Update":"Add"}}
                      </button>

                      <button
                        class="mx-3"
                        mat-raised-button
                        color="primary"
                        routerLink="/dashboard/tabs/city"
                      >
                      Cancel
                      </button>
                    </div>
                  </form>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
  </mat-card-content>
</mat-card>
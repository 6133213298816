@if(resendVerificationLinkFlag==false){<div class="bootstrap-wraper">
  <div class="container">
    <div class="row ml20 mb20">
      <div class="col-md-6 offset-md-3">
        <mat-card class="mt20 mb20" appearance="outlined" color="bule">
          <mat-card-content>
            <div class="container text-center">
              <img src="assets\logo.jpeg" alt="logo" class="imglogo" />
            </div>

            <h3 class="text-center">Register hear!!</h3>

            <form #form="ngForm" (ngSubmit)="registerUser()">
              <!-- Your Name -->
              <mat-form-field class="full-width mb20" appearance="outline">
                <mat-label>Your Name</mat-label>
                <input
                  #name="ngModel"
                  type="text"
                  class="form-control"
                  required
                  minlength="2"
                  maxlength="20"
                  name="name"
                  matInput
                  placeholder="Enter hear"
                  [(ngModel)]="signUp.name"
                />
                <mat-hint>Minimum length 2</mat-hint>
                <span
                  *ngIf="
                    name.invalid &&
                    name.touched &&
                    !form.submitted
                  "
                  class="validationAlertColor"
                  >
                  <span *ngIf="name.errors?.['required']">Name is required.</span>
                  <span *ngIf="name.errors?.['minlength']">Name must be at least 2 and at most 20 characters long.</span>
                  </span
                >
              </mat-form-field>
              <!-- email -->
              <mat-form-field class="full-width mb10" appearance="outline">
                <mat-label>Email</mat-label>
                <input
                  name="email"
                  matInput
                  placeholder="Enter hear"
                  type="email"
                  #userEmail="ngModel"
                  class="form-control"
                  name="email"
                  required
                  email
                  [(ngModel)]="signUp.email"
                />
                <span *ngIf="userEmail.invalid && userEmail.touched && !form.submitted" class="validationAlertColor">
                  <span *ngIf="userEmail.errors?.['required']">Email is required.</span>
                  <span *ngIf="userEmail.errors?.['email']">Please enter a valid email.</span>
                </span>
              </mat-form-field>
              <!-- first Name -->
              <mat-form-field class="full-width mb20" appearance="outline">
                <mat-label>First Name</mat-label>
                <input
                  #firstname="ngModel"
                  type="text"
                  class="form-control"
                  required
                  minlength="2"
                  maxlength="20"
                  name="firstname"
                  matInput
                  placeholder="Enter hear"
                  [(ngModel)]="signUp.firstname"
                />
                <mat-hint>Minimum length 2</mat-hint>
                <span
                  *ngIf="
                    firstname.invalid &&
                    firstname.touched &&
                    !form.submitted
                  "
                  class="validationAlertColor"
                  >
                  <span *ngIf="firstname.errors?.['required']">firstname is required.</span>
                  <span *ngIf="firstname.errors?.['minlength']">firstname must be at least 2 and at most 20 characters long.</span>
                  </span
                >
              </mat-form-field>
              <!-- Last Name -->
              <mat-form-field class="full-width mb20" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input
                  #lastname="ngModel"
                  type="text"
                  class="form-control"
                  required
                  minlength="2"
                  maxlength="20"
                  name="lastname"
                  matInput
                  placeholder="Enter hear"
                  [(ngModel)]="signUp.lastname"
                />
                <mat-hint>Minimum length 2</mat-hint>
                <span
                  *ngIf="
                    lastname.invalid &&
                    lastname.touched &&
                    !form.submitted
                  "
                  class="validationAlertColor"
                  >
                  <span *ngIf="lastname.errors?.['required']">lastname is required.</span>
                  <span *ngIf="lastname.errors?.['minlength']">lastname must be at least 2 and at most 20 characters long.</span>
                  </span
                >
              </mat-form-field>
              <!-- Contact -->
              <mat-form-field class="full-width mb20" appearance="outline">
                <mat-label class="pl-5">Contact</mat-label>
                <div class="inlineElements">
                  <span class="country-code">+91</span>
                  <input
                    #contact="ngModel"
                    type="text"
                    class="form-control"
                    required 
                    pattern = "\d{10}$"
                    name="contact"
                    matInput
                    placeholder="Enter hear"
                    [(ngModel)]="signUp.contact"
                  />
                </div>
                <mat-hint>Write contact like '6375072382'</mat-hint>
                <span
                  *ngIf="
                    contact.invalid &&
                    contact.touched &&
                    !form.submitted
                  "
                  class="validationAlertColor"
                  >
                    <span *ngIf="contact.errors?.['required']">contact is required.</span>
                    <span *ngIf="contact.errors?.['pattern']">Please enter 10 digit number.</span>
                  </span
                >
              </mat-form-field>
              
              <div class="full-width mb20" appearance="outline">
                <span *ngIf="form.invalid" class="validationAlertColor">Please enter required fields.</span>
              </div>

              <div class="container text-center">
                <button class="btn btn-primary" mat-raised-button color="primary" type="submit" [disabled]="form.invalid">
                  Register
                </button>
                <button class="btn btn-primary mx-3" mat-raised-button color="primary" (click)="updateFlagTrue(true)">
                  Resend Verification Link
                </button>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>}
@if(resendVerificationLinkFlag==true)
{
  <div class="bootstrap-wraper">
    <div class="container">
      <div class="row ml20 mb20">
        <div class="col-md-6 offset-md-3">
          <mat-card class="mt20 mb20" appearance="outlined" color="bule">
            <mat-card-content>
              <div class="container text-center">
                <img src="assets\logo.jpeg" alt="logo" class="imglogo" />
              </div>
  
              <h3 class="text-center">Resend Verification Link</h3>
  
              <form #form="ngForm" (ngSubmit)="resendVerificationLink()">
                
                <!-- email -->
                <mat-form-field class="full-width mb10" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input
                    name="email"
                    matInput
                    placeholder="Enter hear"
                    type="text"
                    #userEmail="ngModel"
                    class="form-control"
                    name="email"
                    required
                    email
                    [(ngModel)]="signUp.email"
                  />
                  <mat-hint>Enter email id on which verification link got.</mat-hint>
                  <span *ngIf="userEmail.invalid && userEmail.touched && !form.submitted" class="validationAlertColor">
                    <span *ngIf="userEmail.errors?.['required']">Email is required.</span>
                    <span *ngIf="userEmail.errors?.['email']">Please enter a valid email.</span>
                  </span>
                </mat-form-field>
                
                <div class="full-width mb20" appearance="outline">
                  <span class="validationAlertColor" *ngIf="form.invalid">Please enter required fields.</span>
                </div>
  
                <div class="container text-center">
                  <button class="btn btn-primary" mat-raised-button color="primary" type="submit" [disabled]="form.invalid">
                    Resend Verification Link
                  </button>
                  <!-- <button class="btn btn-primary mx-3" mat-raised-button color="primary" (click)="updateFlagTrue(true)">
                    Cancel
                  </button> -->
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
}



<!-- username -->
<!-- <mat-form-field class="full-width mb10" appearance="outline">
  <mat-label>User Name</mat-label>
  <input
    required
    matInput
    placeholder="Enter hear"
    type="text"
    pattern="[a-zA-Z]+$"
    #userName="ngModel"
    class="form-control"
    name="name"
    [(ngModel)]="signUp.name"
    required
    minlength="3"
    maxlength="15"
  />
  <span
    *ngIf="
      userName.invalid && userName.touched && !userForm.submitted
    "
    style="color: red"
    >Please enter a valid name</span
  >
</mat-form-field>-->

<!-- email -->
<!--<mat-form-field class="full-width mb10" appearance="outline">
  <mat-label>Email</mat-label>
  <input
    name="email"
    matInput
    placeholder="Enter hear"
    type="email"
    #userEmail="ngModel"
    class="form-control"
    name="email"
    required
    email
    [(ngModel)]="signUp.email"
  />
  <span
    *ngIf="
      userEmail.invalid &&
      userEmail.touched &&
      !userForm.submitted
    "
    style="color: red"
    >Please enter a valid email</span
  >
</mat-form-field> -->
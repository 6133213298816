<mat-card>
  <mat-card-content>


      <div class="bootstrap-wraper">
        <div class="container">
          <div class="row ml20 mb20">
            <div class="col-md-6 offset-md-3">
              <mat-card class="mt20 mb20" appearance="outlined" color="bule">
                <mat-card-content>
                  <div class="container text-center">
                    <img src="assets\logo.jpeg" alt="logo" class="imglogo" />
                  </div>
      
                  <h3 class="text-center">{{id!=null && id!=undefined?"Update":"Add"}}-Menu</h3>
      
                  <form #menuForm="ngForm" (ngSubmit)="id!=null && id!=undefined?updateMenu():addMenu();">
                    <!-- Menu Name -->
                    <mat-form-field class="full-width mb20" appearance="outline">
                      <mat-label>Menu Name</mat-label>
                      <input
                        #menuName="ngModel"
                        type="text"
                        pattern="^[a-zA-Z0-9-_ ]+$"
                        class="form-control"
                        required
                        minlength="2"
                        maxlength="30"
                        name="name"
                        matInput
                        placeholder="Enter hear"
                        [(ngModel)]="menuModel.menu_name"
                      />
                      <mat-hint>Minimum length 2</mat-hint>
                      <span
                        *ngIf="
                          menuName.invalid &&
                          menuName.touched &&
                          !menuForm.submitted
                        "
                        class="validationAlertColor"
                        >
                        <span *ngIf="menuName.errors?.['required']">Name is required.</span>
                        <span *ngIf="menuName.errors?.['minlength']">Name must be at least 2 and at most 10 characters long.</span>
                        
                        </span
                      >
                    </mat-form-field>
      
                    <!-- route link -->
                    <mat-form-field class="full-width mb20" appearance="outline">
                      <mat-label>Route Link</mat-label>
                      <input
                        #menuRouteLink="ngModel"
                        type="text"
                        class="form-control"
                        required
                        minlength="2"
                        maxlength="10"
                        name="routeLink"
                        matInput
                        placeholder="Enter hear"
                        [(ngModel)]="menuModel.route_link"
                      />
                      <mat-hint>start with "/"</mat-hint>
                      <span
                        *ngIf="
                          menuRouteLink.invalid &&
                          menuRouteLink.touched &&
                          !menuForm.submitted
                        "
                        class="validationAlertColor"
                        >
                        <span *ngIf="menuRouteLink.errors?.['required']">Route Link is required.</span>
                        <span *ngIf="menuRouteLink.errors?.['minlength']">Route Link must be at least 2 characters long.</span>
                        
                        </span
                      >
                    </mat-form-field>

                    <div class="full-width mb20" appearance="outline">
                      <mat-label class="mr-3">Check It For Active</mat-label>
                      <label class="switch">
                        <input type="checkbox" [checked]="menuModel.is_active" (change)="onCheckboxChange($event)" >
                        <span class="slider round"></span>
                      </label>
                    </div> 

                    <div class="full-width mb20" appearance="outline">
                      <span *ngIf="menuForm.invalid" class="validationAlertColor">Please enter required fields.</span>
                    </div>
      
                    <div class="container text-center">
                      <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        [disabled]="menuForm.invalid"
                        
                      >
                      {{id!=null && id!=undefined?"Update":"Add"}}
                      </button>

                      <button
                        class="mx-3"
                        mat-raised-button
                        color="primary"
                        routerLink="/dashboard/tabs/menu"
                      >
                      Cancel
                      </button>
                    </div>
                  </form>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
      </div>





      
    
    
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-content>
    <div class="bootstrap-wraper">
      <div class="container">
        <div class="row ml20 mb20">
          <div class="col-md-6 offset-md-3">
            <mat-card class="mt20 mb20" appearance="outlined" color="bule">
              <mat-card-content>
                <div class="container text-center">
                  <img
                    *ngIf="id != null && id != undefined"
                    src="{{ API_URL }}/{{ imageUrl }}"
                    (error)="commonMethodsService.handleImageError($event)"
                    class="imglogo"
                  />
                </div>

                <h3 class="text-center">
                  {{ id != null && id != undefined ? "Update" : "Add" }}-Company
                </h3>

                <form
                  #form="ngForm"
                  (ngSubmit)="
                    id != null && id != undefined ? updateData() : addData()
                  "
                >
                  <!-- company_name -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Company Name</mat-label>
                    <input
                      #name="ngModel"
                      type="text"
                      pattern="^[a-zA-Z0-9-_ ]+$"
                      class="form-control"
                      required
                      minlength="2"
                      maxlength="40"
                      name="name"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="companyInfoModel.company_name"
                    />
                    <mat-hint>Minimum length 2</mat-hint>
                    <span
                      *ngIf="name.invalid && name.touched && !form.submitted"
                      class="validationAlertColor"
                    >
                      <span *ngIf="name.errors?.['required']"
                        >Name is required.</span
                      >
                      <span *ngIf="name.errors?.['minlength']"
                        >Name must be at least 2 and at most 30 characters
                        long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- email -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input
                      #email="ngModel"
                      type="email"
                      class="form-control mr10"
                      required
                      minlength="5"
                      name="email"
                      email
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="companyInfoModel.email"
                    />
                    <mat-hint>email</mat-hint>
                    <span
                      *ngIf="email.invalid && email.touched && !form.submitted"
                      class="validationAlertColor"
                    >
                      <span *ngIf="email.errors?.['required']"
                        >Email is required.</span
                      >
                      <span *ngIf="email.errors?.['minlength']"
                        >Email must be at least 5 characters long.</span
                      >
                      <span *ngIf="email.errors?.['email']"
                        >Email must be valid.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- website -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Website</mat-label>
                    <input
                      #website="ngModel"
                      type="text"
                      class="form-control mr10"
                      required
                      minlength="5"
                      name="website"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="companyInfoModel.website"
                    />
                    <mat-hint>website link</mat-hint>
                    <span
                      *ngIf="
                        website.invalid && website.touched && !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="website.errors?.['required']"
                        >website is required.</span
                      >
                      <span *ngIf="website.errors?.['minlength']"
                        >website must be at least 5 characters long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- city_id -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>City id</mat-label>
                    <input
                      #cityId="ngModel"
                      type="number"
                      class="form-control mr10"
                      required
                      minlength="1"
                      name="cityId"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="companyInfoModel.city_id"
                    />
                    <mat-hint>city id</mat-hint>
                    <span
                      *ngIf="
                        cityId.invalid && cityId.touched && !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="cityId.errors?.['required']"
                        >City id is required.</span
                      >
                      <span *ngIf="cityId.errors?.['minlength']"
                        >City id must be at least 1 characters long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- shortInfo -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Short Information</mat-label>
                    <textarea
                      #shortInfo="ngModel"
                      type="text"
                      class="form-control mr10"
                      required
                      name="shortInfo"
                      matInput
                      placeholder="Enter hear"
                      minlength="5"
                      [(ngModel)]="companyInfoModel.short_info"
                    ></textarea>
                    <mat-hint>short info about your course</mat-hint>
                    <span
                      *ngIf="
                        shortInfo.invalid &&
                        shortInfo.touched &&
                        !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="shortInfo.errors?.['required']"
                        >Short Information is required.</span
                      >
                      <span *ngIf="shortInfo.errors?.['minlength']"
                        >Short Information must be at least 5 characters
                        long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- longInfo -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Long Information</mat-label>
                    <textarea
                      #longInfo="ngModel"
                      type="text"
                      class="form-control mr10"
                      required
                      name="longInfo"
                      matInput
                      placeholder="Enter hear"
                      minlength="5"
                      [(ngModel)]="companyInfoModel.long_info"
                    ></textarea>
                    <mat-hint>Long info about your company</mat-hint>
                    <span
                      *ngIf="
                        longInfo.invalid && longInfo.touched && !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="longInfo.errors?.['required']"
                        >Long Information is required.</span
                      >
                      <span *ngIf="longInfo.errors?.['minlength']"
                        >Long Information must be at least 5 characters
                        long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- primary_mobile -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label class="pl-5">Primary mobile</mat-label>
                    <div class="inlineElements">
                      <span class="country-code">+91</span>
                      <input
                        #primaryMobile="ngModel"
                        type="text"
                        class="form-control mr10"
                        required
                        pattern="\d{10}$"
                        name="primaryMobile"
                        matInput
                        placeholder="Enter hear"
                        [(ngModel)]="companyInfoModel.primary_mobile"
                      />
                    </div>
                    <mat-hint>Write contact like '6375072382'</mat-hint>
                    <span
                      *ngIf="
                        primaryMobile.invalid &&
                        primaryMobile.touched &&
                        !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="primaryMobile.errors?.['required']"
                        >Primary mobile is required.</span
                      >
                      <span *ngIf="primaryMobile.errors?.['pattern']"
                        >Please enter 10 digit number.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- secondary_mobile -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label class="pl-5">Secondary mobile</mat-label>
                    <div class="inlineElements">
                      <span class="country-code">+91</span>
                      <input
                        #secondaryMobile="ngModel"
                        type="text"
                        class="form-control mr10"
                        required
                        pattern="\d{10}$"
                        name="secondaryMobile"
                        matInput
                        placeholder="Enter hear"
                        [(ngModel)]="companyInfoModel.secondary_mobile"
                      />
                    </div>
                    <mat-hint>Write contact like '6375072382'</mat-hint>
                    <span
                      *ngIf="
                        secondaryMobile.invalid &&
                        secondaryMobile.touched &&
                        !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="secondaryMobile.errors?.['required']"
                        >Secondary mobile is required.</span
                      >
                      <span *ngIf="secondaryMobile.errors?.['pattern']"
                        >Please enter 10 digit number.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- insta_link -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Insta Link</mat-label>
                    <input
                      #instaLink="ngModel"
                      type="text"
                      class="form-control mr10"
                      required
                      minlength="5"
                      name="instaLink"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="companyInfoModel.insta_link"
                    />
                    <mat-hint>Insta Link</mat-hint>
                    <span
                      *ngIf="
                        instaLink.invalid &&
                        instaLink.touched &&
                        !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="instaLink.errors?.['required']"
                        >Insta Link is required.</span
                      >
                      <span *ngIf="instaLink.errors?.['minlength']"
                        >Insta Link must be at least 5 characters long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- fb_link -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Facebook Link</mat-label>
                    <input
                      #fbLink="ngModel"
                      type="text"
                      class="form-control mr10"
                      required
                      minlength="5"
                      name="fbLink"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="companyInfoModel.fb_link"
                    />
                    <mat-hint>Facebook Link</mat-hint>
                    <span
                      *ngIf="
                        fbLink.invalid && fbLink.touched && !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="fbLink.errors?.['required']"
                        >Facebook Link is required.</span
                      >
                      <span *ngIf="fbLink.errors?.['minlength']"
                        >Facebook Link must be at least 5 characters long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- Image -->
                  <!-- ViewChild -->
                  <app-image-uploads></app-image-uploads>

                  <div class="full-width mb20" appearance="outline">
                    <span *ngIf="form.invalid" class="validationAlertColor"
                      >Please enter required fields.</span
                    >
                  </div>

                  <div class="container text-center">
                    <button
                      mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="form.invalid"
                    >
                      {{ id != null && id != undefined ? "Update" : "Add" }}
                    </button>

                    <button
                      class="mx-3"
                      mat-raised-button
                      color="primary"
                      routerLink="/dashboard/tabs/companyInfo"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

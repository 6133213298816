<mat-card>
  <mat-card-content>
    <div class="bootstrap-wraper">
      <div class="container">
        <div class="row ml20 mb20">
          <div class="col-md-6 offset-md-3">
            <mat-card class="mt20 mb20" appearance="outlined" color="bule">
              <mat-card-content>
                <div class="container text-center">
                  <img
                    *ngIf="id != null && id != undefined"
                    src="{{ API_URL }}/{{ imageUrl }}"
                    (error)="commonMethodsService.handleImageError($event)"
                    alt="logo"
                    class="imglogo"
                  />
                </div>

                <h3 class="text-center">
                  {{ id != null && id != undefined ? "Update" : "Add" }}-Course
                </h3>

                <form
                  #form="ngForm"
                  (ngSubmit)="
                    id != null && id != undefined ? updateData() : addData()
                  "
                >
                  <!-- Course Name -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Course Name</mat-label>
                    <input
                      #name="ngModel"
                      type="text"
                      pattern="^[a-zA-Z0-9-_ ]+$"
                      class="form-control"
                      required
                      minlength="2"
                      maxlength="40"
                      name="name"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="courseModel.course_name"
                    />
                    <mat-hint>Minimum length 2</mat-hint>
                    <span
                      *ngIf="name.invalid && name.touched && !form.submitted"
                      class="validationAlertColor"
                    >
                      <span *ngIf="name.errors?.['required']"
                        >Name is required.</span
                      >
                      <span *ngIf="name.errors?.['minlength']"
                        >Name must be at least 2 and at most 20 characters
                        long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- course_duration -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Course duration</mat-label>
                    <input
                      #courseDuration="ngModel"
                      type="text"
                      class="form-control mr10"
                      required
                      minlength="2"
                      maxlength="20"
                      name="clientCategory"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="courseModel.course_duration"
                    />
                    <mat-hint>Course duration in days like '60 Days'</mat-hint>
                    <span
                      *ngIf="
                        courseDuration.invalid &&
                        courseDuration.touched &&
                        !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="courseDuration.errors?.['required']"
                        >Course duration is required.</span
                      >
                      <span *ngIf="courseDuration.errors?.['minlength']"
                        >Course duration must be at least 2 and at most 20
                        characters long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- course_time -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Course time</mat-label>
                    <input
                      #courseTime="ngModel"
                      type="text"
                      class="form-control mr10"
                      required
                      minlength="2"
                      maxlength="20"
                      name="courseTime"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="courseModel.course_time"
                    />
                    <mat-hint>Daily hours like 'Daily 2 hrs'</mat-hint>
                    <span
                      *ngIf="
                        courseTime.invalid &&
                        courseTime.touched &&
                        !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="courseTime.errors?.['required']"
                        >Course time is required.</span
                      >
                      <span *ngIf="courseTime.errors?.['minlength']"
                        >Course time must be at least 2 and at most 20
                        characters long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- courseMode -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Course mode</mat-label>
                    <input
                      #courseMode="ngModel"
                      type="text"
                      class="form-control mr10"
                      required
                      minlength="2"
                      maxlength="20"
                      name="courseMode"
                      matInput
                      placeholder="Enter hear"
                      [(ngModel)]="courseModel.course_mode"
                    />
                    <mat-hint>Online/Offline</mat-hint>
                    <span
                      *ngIf="
                        courseMode.invalid &&
                        courseMode.touched &&
                        !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="courseMode.errors?.['required']"
                        >Course mode is required.</span
                      >
                      <span *ngIf="courseMode.errors?.['minlength']"
                        >Course mode must be at least 2 and at most 20
                        characters long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- Contact -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label class="pl-5">Contact</mat-label>
                    <div class="inlineElements">
                      <span class="country-code">+91</span>
                      <input
                        #contact="ngModel"
                        type="text"
                        class="form-control mr10"
                        required
                        pattern="\d{10}$"
                        name="contact"
                        matInput
                        placeholder="Enter hear"
                        [(ngModel)]="courseModel.contact"
                      />
                    </div>
                    <mat-hint>Write contact like '6375072382'</mat-hint>
                    <span
                      *ngIf="
                        contact.invalid && contact.touched && !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="contact.errors?.['required']"
                        >Contact is required.</span
                      >
                      <span *ngIf="contact.errors?.['pattern']"
                        >Please enter 10 digit number.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- shortInfo -->
                  <mat-form-field class="full-width mb20" appearance="outline">
                    <mat-label>Short Information</mat-label>
                    <textarea
                      #shortInfo="ngModel"
                      type="text"
                      class="form-control mr10"
                      required
                      name="shortInfo"
                      matInput
                      placeholder="Enter hear"
                      minlength="5"
                      [(ngModel)]="courseModel.short_info"
                    ></textarea>
                    <mat-hint>short info about your course</mat-hint>
                    <span
                      *ngIf="
                        shortInfo.invalid &&
                        shortInfo.touched &&
                        !form.submitted
                      "
                      class="validationAlertColor"
                    >
                      <span *ngIf="shortInfo.errors?.['required']"
                        >Short Information is required.</span
                      >
                      <span *ngIf="shortInfo.errors?.['minlength']"
                        >Short Information must be at least 5 characters
                        long.</span
                      >
                    </span>
                  </mat-form-field>

                  <!-- is active -->
                  <div class="full-width mb20" appearance="outline">
                    <mat-label class="mr-3">Check It For Active</mat-label>
                    <label class="switch">
                      <input
                        type="checkbox"
                        [checked]="courseModel.is_active"
                        (change)="onCheckboxChange($event)"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>

                  <!-- Image -->
                  <!-- ViewChild -->
                  <app-image-uploads></app-image-uploads>

                  <div class="full-width mb20" appearance="outline">
                    <span *ngIf="form.invalid" class="validationAlertColor"
                      >Please enter required fields.</span
                    >
                  </div>

                  <div class="container text-center">
                    <button
                      mat-raised-button
                      color="primary"
                      type="submit"
                      [disabled]="form.invalid"
                    >
                      {{ id != null && id != undefined ? "Update" : "Add" }}
                    </button>

                    <button
                      class="mx-3"
                      mat-raised-button
                      color="primary"
                      routerLink="/dashboard/tabs/courses"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

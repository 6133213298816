import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpErrorInterceptor } from '../../interceptors/http-error.interceptor';
import { ErrorSharedServiceService } from '../../interceptors/error-shared-service.service';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs';
import { ROUTE_HOME } from '../../utils/app-constants';
import { ContextServiceService } from '../../servives/context-service.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.css'
})
export class ErrorPageComponent implements OnInit {
  
  errorCode: any;
  serverErrorCode: any;
  errorMessage: any;

  previousUrl: any = "";
  currentUrl: any;
  urlStartsWithDashboard: any;
  extractedValue: any;

  constructor(
    private route: Router,
    private errorSharedServiceService: ErrorSharedServiceService,
    private requestContext: ContextServiceService,
    private httpClient: HttpClient
  ) {

    //from error page to redirect back
    this.route.events
    .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
    .subscribe((events: RoutesRecognized[]) => {
      this.previousUrl = events[0].urlAfterRedirects;

      const filterValueStr = events[0].urlAfterRedirects.toString();
      this.extractedValue = filterValueStr.includes('/dashboard')
      this.urlStartsWithDashboard = this.route.url.startsWith('/dashboard')
      this.currentUrl = events[1].urlAfterRedirects;
    });

  }

  
 
  


  

  ngOnInit() {
    this.requestContext.setContext('ErrorPageComponent');
    //getting http error code
    this.errorSharedServiceService.errorCode$.subscribe((data) => {
      if(data != "" || null || {} ) {
        this.errorCode = JSON.stringify(data.key);
      }
    });
    
    //getting http server error code
    this.errorSharedServiceService.serverErrorCode$.subscribe((data) => {
      if(data) {
        this.serverErrorCode = JSON.stringify(data.key);
      }
    });

    //getting http error message
    this.errorSharedServiceService.errorMessage$.subscribe((data) => {
      if(data != "" || null) {
        if((this.errorCode == 0) || (this.serverErrorCode == 0) || (this.serverErrorCode == 500)) {
          this.errorMessage = "Server Error.";
        } else {
          this.errorMessage = JSON.stringify(data.key);
        }
      }
    });


    //if data is empty
    if(this.errorCode == null && this.serverErrorCode == null) {
      this.route.navigate([ROUTE_HOME]);
    }
  }
}
